import React from 'react';
import styled from 'styled-components';
import {
  layout,
  LayoutProps,
  space,
  SpaceProps,
  color,
  ColorProps,
} from 'styled-system';
import Box from 'components/atoms/Box';
import { ReactComponent as add_circle } from 'components/icons/add_circle.svg';
import { ReactComponent as enter } from 'components/icons/enter.svg';
import { ReactComponent as home } from 'components/icons/home.svg';
import { ReactComponent as lemonn } from 'components/icons/lemonn.svg';
import { ReactComponent as menu } from 'components/icons/menu.svg';
import { ReactComponent as work } from 'components/icons/work.svg';
import { ReactComponent as trending } from 'components/icons/trending.svg';
import { ReactComponent as drop_down } from 'components/icons/drop-down.svg';
import { ReactComponent as world } from 'components/icons/world.svg';
import { ReactComponent as telegram } from 'components/icons/telegram.svg';
import { ReactComponent as upvote } from 'components/icons/upvote.svg';
import { ReactComponent as search } from 'components/icons/search.svg';
import { ReactComponent as schedule } from 'components/icons/schedule.svg';
import { ReactComponent as done } from 'components/icons/done.svg';
import { ReactComponent as close } from 'components/icons/close.svg';
import { ReactComponent as info } from 'components/icons/info.svg';
import { ReactComponent as add } from 'components/icons/add.svg';
import { ReactComponent as to_the_moon } from 'components/icons/to_the_moon.svg';
import { ReactComponent as twitter } from 'components/icons/twitter.svg';
import { ReactComponent as expand_more } from 'components/icons/expand_more.svg';
import { ReactComponent as prize } from 'components/icons/prize.svg';
import { ReactComponent as account_circle } from 'components/icons/account_circle.svg';
import { ReactComponent as logout } from 'components/icons/logout.svg';
import { ReactComponent as bscscan } from 'components/icons/bscscan.svg';
import { ReactComponent as poo } from 'components/icons/poo.svg';
import { ReactComponent as pancake } from 'components/icons/pancake.svg';
import { ReactComponent as code } from 'components/icons/code.svg';
import { ReactComponent as palette } from 'components/icons/palette.svg';
import { ReactComponent as people } from 'components/icons/people.svg';
import { ReactComponent as notes } from 'components/icons/notes.svg';
import { ReactComponent as group_add } from 'components/icons/group_add.svg';
import { ReactComponent as company } from 'components/icons/company.svg';
import { ReactComponent as pencil } from 'components/icons/pencil.svg';
import { ReactComponent as verified } from 'components/icons/verified.svg';
import { ReactComponent as person_search } from 'components/icons/person_search.svg';
import { ReactComponent as help_outline } from 'components/icons/help_outline.svg';
import { ReactComponent as warning } from 'components/icons/warning.svg';
import { ReactComponent as favorite } from 'components/icons/favorite.svg';
import { ReactComponent as apeswap } from 'components/icons/apeswap.svg';

export const iconsIndex = {
  add,
  to_the_moon,
  info,
  close,
  done,
  schedule,
  upvote,
  add_circle,
  telegram,
  search,
  world,
  drop_down,
  trending,
  enter,
  home,
  lemonn,
  menu,
  work,
  twitter,
  expand_more,
  prize,
  account_circle,
  logout,
  bscscan,
  poo,
  pancake,
  code,
  palette,
  people,
  notes,
  group_add,
  company,
  pencil,
  verified,
  person_search,
  help_outline,
  warning,
  favorite,
  apeswap,
};

export type IconType = keyof typeof iconsIndex;

type BaseIconProps = {
  icon: keyof typeof iconsIndex;
  className?: string;
  onClick?: () => void;
} & React.ComponentPropsWithoutRef<'div'>;

const Icon: React.FunctionComponent<BaseIconProps> = ({
  icon,
  className,
  onClick,
  ...attributes
}) => {
  const RenderedIcon = iconsIndex[icon];
  return (
    <Box
      className={className}
      onClick={onClick}
      display="flex"
      alignItems="center"
      {...attributes}
    >
      <RenderedIcon
        fill="currentColor"
        className="material-icons"
        height="100%"
        width="100%"
      />
    </Box>
  );
};

export type IconProps = BaseIconProps & LayoutProps & ColorProps & SpaceProps;

export default styled(Icon)<IconProps>`
  ${layout}
  ${space}
  ${color}
`;

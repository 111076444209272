import React from 'react';
import classNames from 'classnames';

type SectionProps = {
  alignStart?: boolean;
  alignEnd?: boolean;
  className?: string;
};
const Section: React.FunctionComponent<SectionProps> = ({
  className,
  alignStart,
  children,
  alignEnd,
}) => {
  const sectionClassName = classNames('mdc-top-app-bar__section', className, {
    'mdc-top-app-bar__section--align-start': alignStart,
    'mdc-top-app-bar__section--align-end': alignEnd,
  });
  return <section className={sectionClassName}>{children}</section>;
};

Section.defaultProps = {
  alignStart: false,
};

export default Section;

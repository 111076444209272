import React from 'react';

type UlProps = {
  nav?: boolean;
} & React.HTMLAttributes<HTMLOrSVGElement>;
const Ul: React.FunctionComponent<UlProps> = ({
  children,
  nav,
  ...attributes
}) => {
  const Tag = nav ? 'nav' : 'ul';
  return (
    <Tag {...attributes} className="mdc-list">
      {children}
    </Tag>
  );
};
export default Ul;

import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useWindowWidth } from '@react-hook/window-size/throttled';

import { margin, MarginProps } from 'styled-system';

const Img = styled.img`
  ${margin}
  border-radius: 10px 0;
  height: 100%;
  object-fit: cover;
`;

type AdaptiveSquareImageProps = {
  src: string;
  alt: string;
} & MarginProps;

const AdaptiveSquareImage: React.FunctionComponent<AdaptiveSquareImageProps> =
  ({ src, alt, ...attributes }) => {
    const [imgHeight, setImgHeight] = useState(0);
    const imgRef = useRef<HTMLImageElement>(null);
    const windowWidth = useWindowWidth();
    useEffect(() => {
      setImgHeight(imgRef.current ? imgRef.current.clientHeight : 0);
    }, [windowWidth]);
    return (
      <Img
        ref={imgRef}
        src={src}
        alt={alt}
        style={{ width: imgHeight }}
        {...attributes}
      />
    );
  };

export default AdaptiveSquareImage;

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Page from 'components/templates/Page';
import AddJobForm from 'components/organisms/AddJobForm';
import PublisherInfo from 'components/organisms/PublisherInfo';
import Grid, { Inner, Cell } from 'components/templates/Grid';
import { useLogin } from 'providers/LoginContext';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import LoginButton from 'components/atoms/LoginButton';
import Button from 'components/atoms/Button';
import { Publisher, QueryError } from 'utils/types';
import { useQueryKey } from 'utils/hooks';

const H1 = styled.h1`
  text-align: center;
`;

const H2 = styled.h2`
  text-align: center;
`;

const Warning = styled.p`
  text-align: center;
  font-style: italic;
  color: ${(props) => props.theme.themeOrange};
`;

type AddJobRouteParams = {
  publisher_id: string;
};

const AddJob: React.FunctionComponent = () => {
  const { user } = useLogin();
  const [preventReload, setPreventReload] = useState(false);

  const queryKey = useQueryKey('/jobs/publishers/me/');
  const publishersQuery = useQuery<Publisher[], QueryError>(queryKey, {
    retry: (failureCount, error) =>
      error.response?.status !== 401 && failureCount < 3,
    enabled: !preventReload,
  });

  useEffect(() => {
    if (publishersQuery.isSuccess) {
      setPreventReload(true);
    }
  }, [publishersQuery]);

  const history = useHistory();
  const params = useParams<AddJobRouteParams>();

  const availableProfiles = publishersQuery.isSuccess && publishersQuery.data;
  const selectedProfile =
    availableProfiles &&
    availableProfiles.length > 0 &&
    (params.publisher_id
      ? availableProfiles.find((el) => String(el.id) === params.publisher_id)
        ? availableProfiles.find((el) => String(el.id) === params.publisher_id)
        : availableProfiles[0]
      : availableProfiles[0]);

  return (
    <Page
      title="Lemonn | Post a Job"
      error={
        publishersQuery.isError &&
        publishersQuery.error.response?.status !== 401
      }
      loading={publishersQuery.isLoading}
    >
      <Grid>
        <Inner>
          <Cell solo_column>
            <H1> Post a Job Offer </H1>
            {user &&
              (selectedProfile ? (
                <>
                  <Warning>
                    If you leave this page, your draft will not be saved.
                  </Warning>
                  <h3>You are posting as</h3>
                  <PublisherInfo
                    publisher={selectedProfile}
                    buttonChange
                    height={['68px', '88px']}
                  />
                  <AddJobForm publisher_id={selectedProfile.id} />
                </>
              ) : (
                <>
                  <H2>No Recuiter Profile available</H2>
                  <p style={{ textAlign: 'center' }}>
                    To post a job offer, you should set up your recruiter
                    profile first.
                  </p>
                  <Button
                    raised
                    style={{
                      margin: 'auto',
                      display: 'block',
                      marginTop: '40px',
                    }}
                    onClick={() => history.push('/account/recruiter-profile')}
                  >
                    Continue
                  </Button>
                </>
              ))}
            {!user && (
              <>
                <H2>Please Log In to post job offers.</H2>
                <H2>
                  <LoginButton />
                </H2>
              </>
            )}
          </Cell>
        </Inner>
      </Grid>
    </Page>
  );
};
export default AddJob;

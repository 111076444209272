import React, { useRef, useEffect } from 'react';
import { MDCDrawer } from '@material/drawer';
import classnames from 'classnames';

type DrawerModalProps = { modal: true; dismiss?: false };
type DrawerDismissProps = { dismiss: true; modal?: false };

type DrawerProps = {
  isOpen: boolean;
  className?: string;
  onClose?: Function;
} & (DrawerModalProps | DrawerDismissProps);

const Drawer: React.FunctionComponent<DrawerProps> = ({
  modal,
  isOpen,
  className,
  children,
  onClose,
}) => {
  const drawerRef = useRef<HTMLElement>(null);
  const drawerManager = useRef<ReturnType<typeof MDCDrawer.attachTo>>();

  const drawerClass = classnames('mdc-drawer', className, {
    'mdc-drawer--modal': modal,
  });

  useEffect(() => {
    if (drawerRef.current) {
      drawerManager.current = new MDCDrawer(drawerRef.current);
      drawerRef.current.addEventListener('MDCDrawer:closed', () => {
        onClose && onClose();
      });
    }
    return () => drawerManager.current?.destroy();
  });

  useEffect(() => {
    if (drawerManager.current && isOpen !== drawerManager.current.open) {
      drawerManager.current.open = isOpen;
    }
  }, [isOpen]);

  return (
    <>
      <aside ref={drawerRef} className={drawerClass}>
        {children}
      </aside>
      <div className="mdc-drawer-scrim"></div>
    </>
  );
};

export default Drawer;

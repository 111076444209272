import React from 'react';
import styled from 'styled-components';
import ContentCard, {
  ContentCardLeft,
  ContentCardProps,
  ContentCardRight,
} from 'components/atoms/ContentCard';
import Box from 'components/atoms/Box';
import TokenTitle from 'components/molecules/TokenTitle';
import Status from 'components/atoms/Status';
import { TokenApplication } from 'utils/types';
import { contract_link, twitter_link } from 'utils/links';
import Icon from 'components/atoms/Icon';
import { useModal } from 'providers/ModalContext';
import { displayElapsedTime } from 'utils/format';

interface TokenApplicationInfoProps extends ContentCardProps {
  tokenApplication: TokenApplication;
}
const SubmittedAt = styled.span`
  opacity: 0.6;
  font-size: 0.9em;
`;

const ReviewMessage = styled.span`
  color: ${(props) => props.theme.colors.error};
`;

const StyledIcon = styled(Icon)`
  margin-left: 4px;
  opacity: 0.6;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const TokenApplicationInfo: React.FunctionComponent<TokenApplicationInfoProps> =
  ({ tokenApplication, ...contentCardProps }) => {
    const { open } = useModal();
    return (
      <ContentCard
        height={['60px', '68px']}
        padding="8px 12px 8px 8px"
        fontSize={['0.9em', '1em']}
        {...contentCardProps}
      >
        <ContentCardLeft>
          <TokenTitle
            imgUrl={tokenApplication.image}
            name={tokenApplication.name}
            symbol={tokenApplication.symbol}
            link={
              tokenApplication.token_id
                ? `/token/${tokenApplication.token_id}`
                : undefined
            }
          />
        </ContentCardLeft>
        <ContentCardRight>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
            ml="10px"
            lineHeight="1.2em"
          >
            <SubmittedAt>
              <Box display={['none', 'inline']}>Submitted </Box>
              {displayElapsedTime(tokenApplication.submitted_at)}
            </SubmittedAt>
            <Status status={tokenApplication.status} />
          </Box>
          <StyledIcon
            onClick={() => {
              open(
                <>
                  <h3>Submission Details</h3>
                  <p>
                    <b>Token address: </b>
                    <a
                      className="dialog__link dialog__link--discrete"
                      rel="noreferrer"
                      target="_blank"
                      href={contract_link(tokenApplication.contract_address)}
                    >
                      {tokenApplication.contract_address}
                    </a>
                    <br />
                    <b>Name: </b>
                    {tokenApplication.name}
                    <br />
                    <b>Symbol: </b>
                    {tokenApplication.symbol}
                    <br />
                    <b>Website Address: </b>{' '}
                    <a
                      className="dialog__link dialog__link--discrete"
                      rel="noreferrer"
                      target="_blank"
                      href={tokenApplication.website}
                    >
                      {tokenApplication.website}
                    </a>
                    <br />
                    <b>Twitter Id: </b>
                    <a
                      className="dialog__link dialog__link--discrete"
                      rel="noreferrer"
                      target="_blank"
                      href={twitter_link(tokenApplication.twitter_id)}
                    >
                      {tokenApplication.twitter_id}
                    </a>
                    <br />
                    <i>(Computed from Twitter handle)</i>
                    <br />
                    <b>Telegram invite link: </b>{' '}
                    <a
                      className="dialog__link dialog__link--discrete"
                      rel="noreferrer"
                      target="_blank"
                      href={tokenApplication.telegram_link}
                    >
                      {tokenApplication.telegram_link}
                    </a>
                    <br />
                    {tokenApplication.review_message ? (
                      <ReviewMessage>
                        <b>Review Message :</b>{' '}
                        {tokenApplication.review_message}
                      </ReviewMessage>
                    ) : null}
                  </p>
                  <div className="mdc-dialog__actions">
                    <button
                      type="button"
                      className="mdc-button mdc-dialog__button"
                      data-mdc-dialog-action="discard"
                      data-mdc-dialog-initial-focus
                    >
                      <div className="mdc-button__ripple"></div>
                      <span className="mdc-button__label">Close</span>
                    </button>
                  </div>
                </>
              );
            }}
            icon="info"
          />
        </ContentCardRight>
      </ContentCard>
    );
  };

export default TokenApplicationInfo;

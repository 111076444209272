import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { width, WidthProps } from 'styled-system';
import { MDCMenu, Corner } from '@material/menu';
import classNames from 'classnames';

type MenuProps = {
  children: React.ReactNode;
  className?: string;
} & WidthProps;

const StyledDiv = styled.div`
  ${width}
`;

const Menu = React.forwardRef<HTMLDivElement, MenuProps>(
  ({ children, className, ...attributes }, ref) => {
    return (
      <StyledDiv
        {...attributes}
        ref={ref}
        className={classNames('mdc-menu mdc-menu-surface', className)}
      >
        {children}
      </StyledDiv>
    );
  }
);

export const useMenu = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
  const menuManagerRef = useRef<MDCMenu | null>(null);
  const [menuManager, setMenuManager] = useState<{
    close: () => void;
    open: () => void;
    toggle: () => void;
  }>();

  useEffect(() => {
    const menuElement = ref.current as HTMLDivElement;
    menuManagerRef.current = new MDCMenu(menuElement);
    menuManagerRef.current.setAnchorCorner(Corner.BOTTOM_START);
    menuManagerRef.current.setAnchorMargin({ bottom: 8 });

    setMenuManager({
      close: () => {
        if (menuManagerRef.current) {
          menuManagerRef.current.open = false;
        }
      },
      open: () => {
        if (menuManagerRef.current) {
          menuManagerRef.current.open = true;
        }
      },
      toggle: () => {
        if (menuManagerRef.current && !menuManagerRef.current.open) {
          menuManagerRef.current.open = true;
        }
      },
    });
    return () => {
      menuManagerRef.current?.destroy();
    };
  }, [ref]);
  return { menuManager: menuManager };
};

export default Menu;

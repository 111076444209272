import React from 'react';
import Button from 'components/atoms/Button';
import Box from 'components/atoms/Box';
import styled from 'styled-components';
import axios from 'axios';
import { useQueryClient, useMutation } from 'react-query';
import { useLogin } from 'providers/LoginContext';
import { useModal } from 'providers/ModalContext';

import { Job } from 'utils/types';
import { useQueryKey } from 'utils/hooks';

interface JobManageProps {
  job: Job;
}

const StyledButton = styled(Button)`
  min-width: initial;
`;

const JobManage: React.FunctionComponent<JobManageProps> = ({ job }) => {
  const { user } = useLogin();
  const { open } = useModal();
  const client = useQueryClient();
  const jobsQueryKey = useQueryKey('/jobs/job/me/');

  const manageJobMutation = useMutation(
    async (close: boolean) => {
      if (!user) {
        throw new Error('Missing user');
      }
      const idToken = await user.getIdToken();
      return await axios.post(
        `${process.env.REACT_APP_LEMON_API}/jobs/job/${job.id}/${
          close ? 'close' : 'open'
        }/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        var jobList = client.getQueryData<Job[]>(jobsQueryKey);
        if (jobList) {
          const jobIndex = jobList.findIndex((e) => e.id === job.id);
          jobList[jobIndex] = {
            ...jobList[jobIndex],
            closed: !jobList[jobIndex].closed,
          };
          client.setQueryData(jobsQueryKey, jobList);
        }
      },
    }
  );

  const handleClick = () => {
    open(
      <div>
        {job.closed ? (
          <span>
            Are you sure you want to open the job offer <b>{job.title}</b> ? It
            will appear again in search results.
          </span>
        ) : (
          <span>
            Are you sure you want to close the job offer <b>{job.title}</b> ? It
            won't appear in search results anymore.
          </span>
        )}
        <div className="mdc-dialog__actions">
          <span dir="rtl">
            <button
              type="button"
              className="mdc-button mdc-dialog__button"
              data-mdc-dialog-action="discard"
              data-mdc-dialog-initial-focus
            >
              <div className="mdc-button__ripple"></div>
              <span className="mdc-button__label">Cancel</span>
            </button>
            <button
              type="button"
              className="mdc-button mdc-dialog__button"
              data-mdc-dialog-action="accept"
              onClick={() => manageJobMutation.mutate(!job.closed)}
            >
              <div className="mdc-button__ripple"></div>
              <span className="mdc-button__label">Confirm</span>
            </button>
          </span>
        </div>
      </div>
    );
  };

  return (
    <StyledButton
      outlined
      height={['30px', '36px']}
      width={['56px', '80px']}
      onClick={handleClick}
    >
      <Box fontSize={['0.8em', '1em']}>{job.closed ? 'Open' : 'Close'}</Box>
    </StyledButton>
  );
};

export default JobManage;

import React from 'react';
import FormError from 'components/atoms/FormError';
import axios from 'axios';
import { useLogin } from 'providers/LoginContext';
import AddTokenFormField from './AddTokenFormField';
import { ErrorMessage, Formik, FormikProps, FormikHelpers } from 'formik';
import * as yup from 'yup';
import Button from 'components/atoms/Button';
import { FontIcon } from 'components/atoms/Icon';
import ImageFormTitle from 'components/atoms/ImageFormTitle';
import Box from 'components/atoms/Box';
import FileButton from 'components/atoms/FileButton';
import { useHistory } from 'react-router';
import { useModal } from 'providers/ModalContext';

const validationSchema = yup.object().shape({
  contract_address: yup
    .string()
    .required()
    .matches(
      /^(0x[a-fA-F0-9]{40})$/,
      'Token Address should be 42 alphanumeric characters beginning with 0x.'
    ),
  name: yup.string().required(),
  website: yup
    .string()
    .matches(/^https?:\/\/\S+\.\S+$/, 'This is not a valid website link')
    .required(),
  symbol: yup
    .string()
    .matches(/^\S*$/, 'Token symbol should not contain spaces.')
    .required(),
  twitter_handle: yup
    .string()
    .matches(
      /^@\S+$/,
      'The Twitter Handle must begin with @ and must not contain spaces'
    )
    .required(),
  telegram_link: yup
    .string()
    .matches(
      /^https:\/\/t.me\//,
      'The Telegram invite link should begin with `https://t.me/`'
    )
    .required(),
  image: yup
    .mixed()
    .test(
      'fileType',
      'File format should be jpg or png',
      (value) =>
        !!value && ['image/jpg', 'image/jpeg', 'image/png'].includes(value.type)
    )
    .test(
      'fileSize',
      'File size should not exceed 1MB',
      (value) => !!value && value.size <= 1e6 // 1MB
    )
    .required(),
});

export interface TokenFormValues {
  contract_address: string;
  name: string;
  website: string;
  symbol: string;
  twitter_handle: string;
  telegram_link: string;
  image: undefined;
}

const AddTokenForm: React.FunctionComponent = () => {
  const { user } = useLogin();
  const history = useHistory();
  const { open } = useModal();
  const submitToken = async (
    values: TokenFormValues,
    formikBag: FormikHelpers<TokenFormValues>
  ) => {
    try {
      if (!user) {
        throw new Error('Missing user');
      }
      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('symbol', values.symbol);
      formData.append('contract_address', values.contract_address);
      formData.append('twitter_handle', values.twitter_handle);
      formData.append('telegram_link', values.telegram_link);
      formData.append('website', values.website);
      formData.append('image', values.image!);

      const idToken = await user.getIdToken();

      await axios.post(
        `${process.env.REACT_APP_LEMON_API}/token-race/token/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      history.push('/account/submissions');
      open(
        <div>
          <b>You have successfully submitted a new Token!</b>
          <br />
          <br />
          Our team will review it as soon as possible, this can take a few days.
          You can check its review status in your submissions page.
          <div className="mdc-dialog__actions">
            <button
              type="button"
              className="mdc-button mdc-dialog__button"
              data-mdc-dialog-action="discard"
              data-mdc-dialog-initial-focus
            >
              <div className="mdc-button__ripple"></div>
              <span className="mdc-button__label">Close</span>
            </button>
          </div>
        </div>
      );
    } catch (e) {
      if (e.response.status) {
        formikBag.setFieldError(
          'contract_address',
          'This token has already been added to Lemon'
        );
      }
    }
  };
  return (
    <Formik
      onSubmit={submitToken}
      validationSchema={validationSchema}
      initialValues={{
        contract_address: '',
        name: '',
        website: '',
        symbol: '',
        twitter_handle: '',
        telegram_link: '',
        image: undefined,
      }}
    >
      {({
        handleSubmit,
        handleBlur,
        setFieldValue,
        isSubmitting,
      }: FormikProps<TokenFormValues>) => (
        <form onSubmit={handleSubmit}>
          <h3> Token Info </h3>
          <AddTokenFormField name="contract_address" label="Token Address" />
          <AddTokenFormField name="name" label="Name" />
          <AddTokenFormField name="symbol" label="Symbol" />
          <h3> Links </h3>
          <AddTokenFormField
            icon={<FontIcon icon="world" />}
            name="website"
            label="Website"
          />

          <AddTokenFormField
            icon={<FontIcon icon="twitter" />}
            name="twitter_handle"
            label="Twitter Handle"
          />
          <AddTokenFormField
            icon={<FontIcon icon="telegram" />}
            name="telegram_link"
            label="Telegram Link"
          />
          <ImageFormTitle />
          <Box>
            <FileButton
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (e.target.files && e.target.files[0]) {
                  setFieldValue('image', e.target.files[0]);
                } else {
                  setFieldValue('image', null);
                }
              }}
              onBlur={handleBlur}
              name="image"
              label="Upload a file"
            />
            <ErrorMessage name={'image'}>
              {(msg: string) => <FormError>{msg}</FormError>}
            </ErrorMessage>
          </Box>
          <Box textAlign="center">
            <Button disabled={isSubmitting} type="submit" raised>
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
export default AddTokenForm;

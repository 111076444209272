import React from 'react';
import Button from 'components/atoms/Button';

export const LoginButton: React.FunctionComponent = () => {
  const login = () => {
    window.location.href = `https://accounts.moonlighttoken.com/authenticate/?landing=lemonn&callback=${window.location.href}`;
  };
  return (
    <Button onClick={login} raised>
      Log In
    </Button>
  );
};
export default LoginButton;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'components/atoms/Button';

type NavLinkProps = React.ComponentProps<typeof NavLink>;
const StyledNavLink = styled(NavLink)<NavLinkProps>`
  &&& {
    color: ${(props) => props.theme.themePastelPurple};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const ViewAllJobs: React.FunctionComponent = () => {
  const history = useHistory();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Button
        outlined
        style={{ minWidth: 'min(80%, 330px)' }}
        onClick={() => history.push('/jobs')}
      >
        View All Jobs
      </Button>
      <span
        style={{
          marginTop: '8px',
          fontSize: '0.9em',
          opacity: '0.8',
        }}
      >
        Hiring? <StyledNavLink to="/post-job">Post a Job</StyledNavLink>
      </span>
    </div>
  );
};

export default ViewAllJobs;

import React, { useRef, useEffect } from 'react';
import { MDCTopAppBar } from '@material/top-app-bar';
import classnames from 'classnames';

type TopAppBarProps = {
  fixed?: boolean;
  className?: string;
};
const TopAppBar: React.FunctionComponent<TopAppBarProps> = ({
  fixed,
  children,
  className,
}) => {
  const manager = useRef<MDCTopAppBar | null>(null);
  const TopAppBarRef = useRef<HTMLHeadingElement | null>(null);

  useEffect(() => {
    if (TopAppBarRef.current) {
      manager.current = new MDCTopAppBar(TopAppBarRef.current);
    }
    return () => manager.current?.destroy();
  }, []);

  const topAppBarClassName = classnames('mdc-top-app-bar', className, {
    'mdc-top-app-bar--fixed': fixed,
  });
  return (
    <header ref={TopAppBarRef} className={topAppBarClassName}>
      {children}
    </header>
  );
};

export default TopAppBar;

import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
};

firebase.initializeApp(firebaseConfig);

export const signIn = (customToken: string) => {
  firebase.auth().signInWithCustomToken(customToken);
};

export const signOut = async () => {
  return await firebase.auth().signOut();
};

export const login = () => {
  window.location.href = `https://accounts.moonlighttoken.com/authenticate/?callback=${window.location.href}`;
};

export default firebase;

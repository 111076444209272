import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import TokenApplicationInfo from 'components/organisms/TokenApplicationInfo';
import RecruiterSubmissionInfo from 'components/organisms/RecruiterSubmissionInfo';
import JobSubmissionInfo from 'components/organisms/JobSubmissionInfo';
import {
  TokenApplication,
  RecruiterSubmission,
  JobSubmission,
  QueryError,
} from 'utils/types';
import Page from 'components/templates/Page';
import Ul from 'components/atoms/Ul';
import { useQuery } from 'react-query';
import Grid, { Inner, Cell } from 'components/templates/Grid';
import { useLogin } from 'providers/LoginContext';
import LoginButton from 'components/atoms/LoginButton';
import { useHistory } from 'react-router-dom';
import Button from 'components/atoms/Button';
import Box from 'components/atoms/Box';
import { useQueryKey } from 'utils/hooks';

const H1 = styled.h1`
  text-align: center;
`;
const H2 = styled.h2`
  text-align: center;
`;

const NoSubmission = styled.div`
  text-align: center;
  font-style: italic;
  color: ${(props) => props.theme.themeOrange};
`;

const Submissions = () => {
  const { user } = useLogin();
  const tokensQueryKey = useQueryKey('/token-race/application/me/');
  const applicationsQuery = useQuery<TokenApplication[], QueryError>(
    tokensQueryKey,
    {
      retry: (failureCount, error) =>
        error.response?.status !== 401 && failureCount < 3,
    }
  );
  const profilesQueryKey = useQueryKey('/jobs/recruiter-submission/me/');
  const profileSubmissionsQuery = useQuery<RecruiterSubmission[], QueryError>(
    profilesQueryKey,
    {
      retry: (failureCount, error) =>
        error.response?.status !== 401 && failureCount < 3,
    }
  );
  const jobsQueryKey = useQueryKey('/jobs/job-submission/me/');
  const jobSubmissionsQuery = useQuery<JobSubmission[], QueryError>(
    jobsQueryKey,
    {
      retry: (failureCount, error) =>
        error.response?.status !== 401 && failureCount < 3,
    }
  );
  const history = useHistory();

  return (
    <Page
      title="Lemonn | Submissions"
      error={
        (applicationsQuery.isError &&
          applicationsQuery.error.response?.status !== 401) ||
        (profileSubmissionsQuery.isError &&
          profileSubmissionsQuery.error.response?.status !== 401) ||
        (jobSubmissionsQuery.isError &&
          jobSubmissionsQuery.error.response?.status !== 401)
      }
      loading={
        applicationsQuery.isLoading ||
        profileSubmissionsQuery.isLoading ||
        jobSubmissionsQuery.isLoading
      }
    >
      <Grid>
        <Inner>
          <Cell solo_column>
            <H1> Your Submissions </H1>
            {user && (
              <>
                <H2>Tokens</H2>
                <Ul style={{ padding: '0' }}>
                  {applicationsQuery.isSuccess &&
                    (applicationsQuery.data.length > 0 ? (
                      applicationsQuery.data
                        .sort((firstEl, secondEl) => {
                          return (
                            dayjs(secondEl.submitted_at).valueOf() -
                            dayjs(firstEl.submitted_at).valueOf()
                          );
                        })
                        .map((tokenApplication) => (
                          <li
                            key={tokenApplication.id}
                            style={{ marginBottom: '10px' }}
                          >
                            <TokenApplicationInfo
                              tokenApplication={tokenApplication}
                            />
                          </li>
                        ))
                    ) : (
                      <NoSubmission>
                        You have not submitted any token yet.
                        <Box mt="16px" mb="28px">
                          <Button
                            outlined
                            onClick={() => history.push('/add-token')}
                          >
                            Add a Token
                          </Button>
                        </Box>
                      </NoSubmission>
                    ))}
                </Ul>
                <H2>Custom Recruiter Profiles</H2>
                <Ul style={{ padding: '0' }}>
                  {profileSubmissionsQuery.isSuccess &&
                    (profileSubmissionsQuery.data.length > 0 ? (
                      profileSubmissionsQuery.data
                        .sort((firstEl, secondEl) => {
                          return (
                            dayjs(secondEl.submitted_at).valueOf() -
                            dayjs(firstEl.submitted_at).valueOf()
                          );
                        })
                        .map((recruiterSubmission) => (
                          <li
                            key={recruiterSubmission.id}
                            style={{ marginBottom: '10px' }}
                          >
                            <RecruiterSubmissionInfo
                              recruiterSubmission={recruiterSubmission}
                            />
                          </li>
                        ))
                    ) : (
                      <NoSubmission>
                        You have not submitted any recruiter profile yet.
                        <Box mt="16px" mb="28px">
                          <Button
                            outlined
                            onClick={() => history.push('/create-profile')}
                          >
                            Create a Profile
                          </Button>
                        </Box>
                      </NoSubmission>
                    ))}
                </Ul>
                <H2>Job Offers</H2>
                <Ul style={{ padding: '0' }}>
                  {jobSubmissionsQuery.isSuccess &&
                    (jobSubmissionsQuery.data.length > 0 ? (
                      jobSubmissionsQuery.data
                        .sort((firstEl, secondEl) => {
                          return (
                            dayjs(secondEl.submitted_at).valueOf() -
                            dayjs(firstEl.submitted_at).valueOf()
                          );
                        })
                        .map((jobSubmission) => (
                          <li
                            key={jobSubmission.id}
                            style={{ marginBottom: '10px' }}
                          >
                            <JobSubmissionInfo jobSubmission={jobSubmission} />
                          </li>
                        ))
                    ) : (
                      <NoSubmission>
                        You have not submitted any job offer yet.
                        <Box mt="16px" mb="28px">
                          <Button
                            outlined
                            onClick={() => history.push('/post-job')}
                          >
                            Post a Job
                          </Button>
                        </Box>
                      </NoSubmission>
                    ))}
                </Ul>
              </>
            )}
            {!user && (
              <>
                <H2>Please Log In to access your submissions.</H2>
                <H2>
                  <LoginButton />
                </H2>
              </>
            )}
          </Cell>
        </Inner>
      </Grid>
    </Page>
  );
};

export default Submissions;

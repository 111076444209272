import React, { useState } from 'react';
import { useInfiniteJobQuery } from 'utils/api/hooks';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useQueryKey } from 'utils/hooks';
import { Publisher } from 'utils/types';
import Page from 'components/templates/Page';
import Grid, { Inner, Cell } from 'components/templates/Grid';
import Section, { Header } from 'components/templates/Section';
import Ul from 'components/atoms/Ul';
import JobInfo from 'components/organisms/JobInfo';
import Button from 'components/atoms/Button';
import ContentCard from 'components/atoms/ContentCard';
import Loader from 'components/atoms/Loader';
import Box from 'components/atoms/Box';
import Select from 'components/atoms/Select';
import styled from 'styled-components';
import PublisherInfo from 'components/organisms/PublisherInfo';

const FilterHeader = styled(Header)`
  .filters-container .mdc-select:not(:first-child) {
    margin-left: 8px;
  }
  .filters-container {
    display: flex;
  }

  @media (max-width: 599px) {
    flex-direction: column;
    align-items: flex-start;
    height: auto;

    .filters-container {
      width: 100%;

      .mdc-select {
        margin-top: 10px;
        width: calc(50% - 4px);

        &:not(:first-child) {
          margin-left: 8px;
        }
      }
    }
  }
`;

const CustomFirstCell = styled(Cell)`
  @media (min-width: 920px) and (max-width: 1199px) {
    grid-column-end: span 8;
  }
`;

const CustomSecondCell = styled(Cell)`
  @media (min-width: 920px) and (max-width: 1199px) {
    grid-column-end: span 4;
  }
`;

const JobHub: React.FunctionComponent = () => {
  const [fieldFilter, setFieldFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [resetFiltersKey, setResetFiltersKey] = useState(0);
  const jobsQuery = useInfiniteJobQuery(typeFilter, fieldFilter);
  // This is used to distinguish the initial jobs load from the search ones
  const [filterChanged, setFilterChanged] = useState(false);
  const jobsList = jobsQuery.isSuccess
    ? jobsQuery.data.pages.map((page) => page.tokens).flat()
    : [];
  const history = useHistory();

  const featuredRecruitersQueryKey = useQueryKey('/jobs/publishers/featured/');
  const featuredRecruitersQuery = useQuery<Publisher[]>(
    featuredRecruitersQueryKey
  );

  return (
    <Page
      title="Lemonn | Jobs"
      error={jobsQuery.isError || featuredRecruitersQuery.isError}
      loading={
        featuredRecruitersQuery.isLoading ||
        (jobsQuery.isLoading && !filterChanged)
      }
    >
      <Grid>
        <Inner>
          <CustomFirstCell span={9}>
            <Section>
              <FilterHeader
                icon="work"
                title="Jobs"
                help={
                  <>
                    <h2>Everyone can work on BSC. Why not you?</h2>
                    <p>
                      Everything here is full remote jobs or freelance missions,
                      and most of the offers are subject to very flexible terms.
                      Recruitement processes are often simple, and the skills
                      requirements can be quite accessible.
                    </p>
                    <p>Want to work on BSC? You can do it!</p>
                  </>
                }
              >
                <span className="filters-container" key={resetFiltersKey}>
                  <Select
                    width="200px"
                    name="field"
                    label="Filter by Type"
                    options={[
                      { value: 'full_time', label: 'Full Time' },
                      { value: 'part_time', label: 'Part Time' },
                      { value: 'commission', label: 'Commission' },
                      { value: 'contest', label: 'Contest' },
                    ]}
                    discrete
                    withEmpty
                    // onChange event doesn't fire on hidden inputs
                    customOnChange={(value) => {
                      setFilterChanged(true);
                      setTypeFilter(value);
                    }}
                  />
                  <Select
                    width="200px"
                    name="field"
                    label="Filter by Field"
                    options={[
                      { value: 'growth', label: 'Growth' },
                      { value: 'technology', label: 'Technology' },
                      { value: 'art', label: 'Art & Design' },
                      { value: 'community', label: 'Community' },
                      { value: 'content', label: 'Content' },
                      { value: 'other', label: 'Other' },
                    ]}
                    discrete
                    withEmpty
                    // onChange event doesn't fire on hidden inputs
                    customOnChange={(value) => {
                      setFilterChanged(true);
                      setFieldFilter(value);
                    }}
                  />
                </span>
              </FilterHeader>
              {jobsQuery.isLoading ? (
                <Box display="flex" justifyContent="center" marginTop="50px">
                  <Loader size={100} />
                </Box>
              ) : (
                <Ul style={{ padding: '0' }}>
                  {jobsList.map((job) => (
                    <li key={job.id} style={{ marginBottom: '10px' }}>
                      <JobInfo key={job.publisher.name} job={job} wide />
                    </li>
                  ))}
                  {jobsList.length < 10 || !jobsQuery.hasNextPage ? (
                    <Box textAlign="center" marginTop="20px">
                      {jobsList.length === 0 ? (
                        <p>
                          There is currently no open job matching your filters.
                        </p>
                      ) : (
                        !jobsQuery.hasNextPage && (
                          <Box textAlign="center" height="36px" opacity="0.5">
                            <p>No more available results</p>
                          </Box>
                        )
                      )}
                      {(fieldFilter !== '' || typeFilter !== '') && (
                        <Box display="inline-block" marginLeft="10px">
                          <Button
                            outlined
                            onClick={() => {
                              setTypeFilter('');
                              setFieldFilter('');
                              setResetFiltersKey(resetFiltersKey + 1);
                            }}
                          >
                            Reset Filters
                          </Button>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    jobsQuery.hasNextPage &&
                    (jobsQuery.isFetchingNextPage ? (
                      <Box display="flex" justifyContent="center">
                        <Loader size={36} />
                      </Box>
                    ) : (
                      <Box textAlign="center">
                        <Button onClick={() => jobsQuery.fetchNextPage()}>
                          Load More
                        </Button>
                      </Box>
                    ))
                  )}
                </Ul>
              )}
            </Section>
          </CustomFirstCell>
          <CustomSecondCell tablet_span={8} desktop_span={3}>
            <Section>
              <Header icon="group_add" title="Are you Hiring?" />
              <ContentCard padding="12px">
                <Button
                  raised
                  onClick={() => history.push('/post-job')}
                  style={{ width: '100%' }}
                >
                  Post a Job
                </Button>
              </ContentCard>
            </Section>
            <Section>
              <Header
                icon="favorite"
                title="Supporting Recruiters"
                help={
                  <>
                    <p>
                      These recruiters have shown love and support for the
                      Lemonn community.
                    </p>
                    <p>
                      You think you deserve to appear here? You hold a lot of
                      Lemonn tokens? Don't hesitate to{' '}
                      <a
                        href={`mailto:contact@lemonn.io?subject=Supporting%20Recruiter%20Contact`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        contact us.
                      </a>
                    </p>
                  </>
                }
              />
              {featuredRecruitersQuery.isSuccess && (
                <Ul style={{ padding: '0' }}>
                  {featuredRecruitersQuery.data.map((publisher) => (
                    <li key={publisher.id} style={{ marginBottom: '10px' }}>
                      <PublisherInfo
                        publisher={publisher}
                        height={['56px', '68px']}
                      />
                    </li>
                  ))}
                </Ul>
              )}
            </Section>
          </CustomSecondCell>
        </Inner>
      </Grid>
    </Page>
  );
};
export default JobHub;

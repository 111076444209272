import React from 'react';
import styled from 'styled-components';
import PublisherInfo from 'components/organisms/PublisherInfo';
import { Publisher, QueryError } from 'utils/types';
import Page from 'components/templates/Page';
import Ul from 'components/atoms/Ul';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Grid, { Inner, Cell } from 'components/templates/Grid';
import { useLogin } from 'providers/LoginContext';
import LoginButton from 'components/atoms/LoginButton';
import Section, { Header } from 'components/templates/Section';
import Button from 'components/atoms/Button';
import { useQueryKey } from 'utils/hooks';
import ContentCard from 'components/atoms/ContentCard';
import { useModal } from 'providers/ModalContext';
import { ReactComponent as LemonWithCoin } from 'components/icons/lemon-with-coin.svg';
import { ReactComponent as LemonWithMic } from 'components/icons/lemon-with-mic.svg';

const H1 = styled.h1`
  text-align: center;
`;
const H2 = styled.h2`
  text-align: center;
`;
const NoProfile = styled.p`
  font-style: italic;
  color: ${(props) => props.theme.themeOrange};
`;
const WelcomeButton = styled(ContentCard)`
  text-align: center;
  padding: 30px;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.theme.themeDarkPurple};
  }
`;

const Submissions = () => {
  const { user } = useLogin();
  const queryKey = useQueryKey('/jobs/publishers/me/');
  const publishersQuery = useQuery<Publisher[], QueryError>(queryKey, {
    retry: (failureCount, error) =>
      error.response?.status !== 401 && failureCount < 3,
  });

  const history = useHistory();
  const { open } = useModal();

  const tokenProfiles =
    publishersQuery.isSuccess &&
    publishersQuery.data.filter((publisher) => publisher.is_token);

  const customProfiles =
    publishersQuery.isSuccess &&
    publishersQuery.data.filter((publisher) => !publisher.is_token);

  const toSetup =
    (!tokenProfiles || tokenProfiles.length === 0) &&
    (!customProfiles || customProfiles.length === 0);

  const tokenProfileHelp = user ? (
    <>
      <h2>Managing job offers for a Token</h2>
      <p>
        As long as your account is linked with the Twitter profile of a given
        token, you can impersonate this token when posting and managing job
        offers.
      </p>
      <p>
        Use{' '}
        <a
          href="https://accounts.moonlighttoken.com/"
          target="_blank"
          rel="noreferrer"
        >
          the Moonlight account platorm
        </a>{' '}
        to link your account to a Twitter profile. Ensure that this is the same
        Twitter profile as the one specified on the Lemonn page of the token you
        want to impersonate. It should then appear on this page.
      </p>
      <p>
        If you changed your account on the account platform, please log out and
        log in again here to ensure you're using the correct account.
      </p>
      <p>
        If you need help, or if you do not have direct access to the Twitter
        account of the token you represent, please{' '}
        <a
          href={`mailto:help@lemonn.io?subject=Token%20Profile&body={uid:${user.uid}}`}
          target="_blank"
          rel="noreferrer"
        >
          contact our support team.
        </a>
      </p>
    </>
  ) : (
    <></>
  );

  return (
    <Page
      title="Lemonn | Recruiter Profile"
      error={
        publishersQuery.isError &&
        publishersQuery.error.response?.status !== 401
      }
      loading={publishersQuery.isLoading}
    >
      <Grid>
        <Inner>
          <Cell solo_column>
            <H1>{toSetup && 'Setup your '}Recruiter Profile</H1>

            {user &&
              (toSetup ? (
                <>
                  <WelcomeButton
                    onClick={() =>
                      open(
                        <div>
                          {tokenProfileHelp}
                          <div className="mdc-dialog__actions">
                            <button
                              type="button"
                              className="mdc-button mdc-dialog__button"
                              data-mdc-dialog-action="discard"
                              data-mdc-dialog-initial-focus
                            >
                              <div className="mdc-button__ripple"></div>
                              <span className="mdc-button__label">Close</span>
                            </button>
                          </div>
                        </div>
                      )
                    }
                  >
                    <LemonWithCoin
                      style={{ height: '200px', marginRight: '20px' }}
                    />
                    <h2>Post jobs for a Token</h2>
                  </WelcomeButton>
                  <WelcomeButton
                    onClick={() =>
                      open(
                        <div>
                          <p>
                            You can create custom recruiter profiles to post
                            jobs with, they won't be linked to any token in
                            particular.
                          </p>
                          <p>
                            If you have already created a custom profile, it has
                            not been approved yet. Check its status in{' '}
                            <span
                              style={{ cursor: 'pointer', color: '#a872ff' }}
                              onClick={() =>
                                history.push('/account/submissions')
                              }
                              data-mdc-dialog-action="discard"
                            >
                              your submissions page.
                            </span>
                          </p>
                          <Button
                            raised
                            style={{
                              margin: 'auto',
                              display: 'block',
                              marginTop: '40px',
                            }}
                            onClick={() => history.push('/create-profile')}
                            data-mdc-dialog-action="discard"
                          >
                            Create a custom profile
                          </Button>
                          <div className="mdc-dialog__actions">
                            <button
                              type="button"
                              className="mdc-button mdc-dialog__button"
                              data-mdc-dialog-action="discard"
                              data-mdc-dialog-initial-focus
                            >
                              <div className="mdc-button__ripple"></div>
                              <span className="mdc-button__label">Close</span>
                            </button>
                          </div>
                        </div>
                      )
                    }
                  >
                    <LemonWithMic
                      style={{ height: '180px', marginRight: '20px' }}
                    />
                    <h2>Post jobs for a non-token organization</h2>
                  </WelcomeButton>
                </>
              ) : (
                <>
                  <Section>
                    <Header
                      icon="verified"
                      title="Token Profile"
                      help={tokenProfileHelp}
                    />
                    <p>
                      As long as your account is linked with the Twitter profile
                      of a given token, you can impersonate this token when
                      posting and managing job offers.
                    </p>
                    {tokenProfiles && tokenProfiles.length > 0 ? (
                      <Ul style={{ padding: '0' }}>
                        {tokenProfiles.map((publisher) => (
                          <li
                            key={publisher.id}
                            style={{ marginBottom: '10px' }}
                          >
                            <PublisherInfo
                              publisher={publisher}
                              height={['68px', '88px']}
                              buttonPost
                            />
                          </li>
                        ))}
                      </Ul>
                    ) : (
                      <NoProfile>
                        Your account is currently not linked to a Twitter
                        profile that matches a token listed on Lemonn.
                      </NoProfile>
                    )}
                  </Section>
                  <Section>
                    <Header icon="pencil" title="Custom Profiles" />
                    <p>
                      You can create custom recruiter profiles to post jobs
                      with, they won't be linked to any token in particular.
                    </p>
                    {customProfiles && customProfiles.length > 0 ? (
                      <Ul style={{ padding: '0' }}>
                        {customProfiles.map((publisher) => (
                          <li
                            key={publisher.id}
                            style={{ marginBottom: '10px' }}
                          >
                            <PublisherInfo
                              publisher={publisher}
                              height={['56px', '68px']}
                              buttonPost
                            />
                          </li>
                        ))}
                      </Ul>
                    ) : (
                      <NoProfile>
                        You do not have any custom profile yet.
                      </NoProfile>
                    )}
                  </Section>
                  <Button
                    raised
                    style={{
                      margin: 'auto',
                      display: 'block',
                      marginTop: '40px',
                    }}
                    onClick={() => history.push('/create-profile')}
                  >
                    Create a custom profile
                  </Button>
                </>
              ))}
            {!user && (
              <>
                <H2>Please Log In to access your recruiter profile.</H2>
                <H2>
                  <LoginButton />
                </H2>
              </>
            )}
          </Cell>
        </Inner>
      </Grid>
    </Page>
  );
};

export default Submissions;

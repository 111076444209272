import React, { useEffect } from 'react';
import MDCPage from './Material/MDCPage';
import styled, { keyframes } from 'styled-components';
import { useLogin } from 'providers/LoginContext';
import Loader from 'components/atoms/Loader';

// Moonlight resets
const Page = styled(MDCPage)`
  min-height: 100vh;
  width: 100%;
`;

const FadeInKeyframes = keyframes`
  0% {opacity:0; top:30px;}
  100% {opacity:1; top:0;}
`;

const FadeIn = styled.div`
  animation: ${FadeInKeyframes} 0.3s;
  position: relative;
  width: 100%;
`;

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 700px;
  padding: 56px 10px 0 10px;
  min-height: calc(100vh - 56px);
  margin: auto;
  line-height: 1.3em;

  a {
    color: ${(props) => props.theme.themePastelPurple};

    &:hover {
      text-decoration: underline;
      color: ${(props) => props.theme.themePastelPurple} !important;
  }
`;

type MetaDataProps = {
  title?: string;
  error?: boolean;
  loading?: boolean;
};
const PageWithMetaData: React.FunctionComponent<MetaDataProps> = ({
  title,
  error,
  loading,
  children,
}) => {
  useEffect(() => {
    document.title = title ? title : 'Lemonn';
    return () => {
      document.title = 'Lemonn';
    };
  }, [title]);
  const { user } = useLogin();
  return (
    <Page>
      {error ? (
        <Centered>
          <span>
            An unexpected error has occured, please verify your internet
            connection and try to refresh. <br />
            If the problem persists, please{' '}
            <a
              href={`mailto:help@lemonn.io?subject=Loading%20Error`}
              target="_blank"
              rel="noreferrer"
            >
              contact our help desk.
            </a>
          </span>
        </Centered>
      ) : user === undefined || loading ? (
        <Centered>
          <Loader size={120} />
        </Centered>
      ) : (
        <FadeIn>{children}</FadeIn>
      )}
    </Page>
  );
};

export default PageWithMetaData;

import React, { useRef } from 'react';
import { iconsIndex } from 'components/atoms/Icon/Icon';
import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';

import Ul from 'components/atoms/Ul';
import LiLink from 'components/atoms/LiLink';
import Menu, { useMenu } from 'components/atoms/Menu';

export type Link = {
  name: string;
  url: string;
  icon: keyof typeof iconsIndex;
};
type LinksProps = {
  links: Link[];
};
const Links: React.FunctionComponent<LinksProps> = ({ links }) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { menuManager } = useMenu(menuRef);

  return (
    <div dir="rtl" className="mdc-menu-surface--anchor">
      <Button
        onClick={() => {
          document.body.addEventListener(
            'click',
            (e) => {
              e.stopPropagation();
            },
            {
              capture: true,
              once: true,
            }
          );
          if (menuManager) {
            menuManager.toggle();
          }
        }}
      >
        <Icon height="24px" width="24px" icon="drop_down" />
        Links
      </Button>
      <Menu ref={menuRef} width="165px">
        <Ul tabIndex={-1}>
          {links.map((link) => {
            return (
              <LiLink
                key={link.name}
                flexEnd
                path={link.url}
                title={link.name}
                rtl
                external
              >
                <Icon width="16px" height="16px" icon={link.icon} />
              </LiLink>
            );
          })}
        </Ul>
      </Menu>
    </div>
  );
};

export default Links;

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { variant, layout, LayoutProps } from 'styled-system';
import classNames from 'classnames';

import { MDCRipple } from '@material/ripple';

// Variants
const shapes = {
  rounded: {
    borderRadius: '1000px',
    margin: '5px',
    padding: '22px',
  },
};

const states = (theme: any) => ({
  fancy: {
    background: 'linear-gradient(45deg, #ff8d00, #ffee4f)',
    color: theme.themeDarkPurple,
    border: '1px solid transparent',
  },
  activated: {
    color: theme.themeOrange,
    borderColor: theme.themeOrange,
  },
});

export type ShapeVariant = keyof typeof shapes;
export type stateVariant = keyof ReturnType<typeof states>;

type BaseButtonProps = {
  shape?: ShapeVariant;
  state?: stateVariant;
};

const BaseButton = styled.button<BaseButtonProps>`
  ${layout}
  &&& {
    ${variant({ prop: 'shape', variants: shapes })}
    ${(props) => variant({ prop: 'state', variants: states(props.theme) })}
  }
`;

type ButtonProps = {
  children: React.ReactNode;
  outlined?: boolean;
  raised?: boolean;
  className?: string;
} & BaseButtonProps &
  React.ComponentPropsWithoutRef<'button'> &
  LayoutProps;
const Button: React.FunctionComponent<ButtonProps> = ({
  className,
  outlined,
  raised,
  children,
  ...attributes
}: ButtonProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const rippleRef = useRef<MDCRipple | null>(null);

  useEffect(() => {
    if (buttonRef.current) {
      rippleRef.current = new MDCRipple(buttonRef.current);
    }
    return () => {
      if (rippleRef.current) {
        rippleRef.current.destroy();
      }
    };
  }, []);
  const buttonClass = classNames('mdc-button', className, {
    'mdc-button--outlined': outlined,
    'mdc-button--raised': raised,
  });
  return (
    <BaseButton ref={buttonRef} {...attributes} className={buttonClass}>
      <span className="mdc-button__ripple" />
      {children}
    </BaseButton>
  );
};

export default Button;

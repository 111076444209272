// eslint-disable-next-line import/no-webpack-loader-syntax
const moonlightTheme = require('sass-extract-loader?{"plugins":["sass-extract-js"]}!../node_modules/@moonlighttoken/material-theme/variables.scss');
//

const breakpoints = ['600px', '920px'];
const mediaQueries = {
  tablet: `@media screen and (min-width: ${breakpoints[0]})`,
  desktop: `@media screen and (min-width: ${breakpoints[1]})`,
};
export const theme = {
  ...moonlightTheme,
  twitterColor: '#08a0e9',
  metamaskColor: '#ec8120',
  breakpoints: breakpoints,
  mediaQueries: mediaQueries,
  colors: {
    error: '#b00020',
    green: '#009688',
  },
};

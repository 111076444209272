import React from 'react';
import Drawer, { DrawerContent } from 'components/molecules/Drawer';
import Ul from 'components/atoms/Ul';
import Divider from 'components/atoms/Divider';
import LiLink from 'components/atoms/LiLink';
import { useLogin } from 'providers/LoginContext';
import Li from 'components/atoms/Li';

import { login, signOut } from 'utils/auth';

type MobileDrawerProps = {
  isOpen: boolean;
  close: Function;
};
const MobileDrawer: React.FunctionComponent<MobileDrawerProps> = ({
  isOpen,
  close,
}) => {
  const { user } = useLogin();
  return (
    <Drawer isOpen={isOpen} onClose={close} modal>
      <DrawerContent>
        <Ul nav onClick={() => close()}>
          <LiLink path="/hub" graphic="home">
            Token Hub
          </LiLink>
          <LiLink path="/add-token" graphic="add_circle">
            Add a Token
          </LiLink>
          <LiLink path="/jobs" graphic="work">
            Jobs
          </LiLink>
          <LiLink path="/about" graphic="help_outline">
            About
          </LiLink>
          <Divider />
          {!user && (
            <Li onClick={login} tabIndex={0} gradient graphic="enter">
              LOG IN
            </Li>
          )}
          {user && (
            <>
              <LiLink path="/account/submissions" graphic="verified">
                My Submissions
              </LiLink>
              <LiLink path="/account/jobs" graphic="person_search">
                My Job Offers
              </LiLink>
              <LiLink path="/post-job" graphic="group_add">
                Post a Job
              </LiLink>
              <LiLink path="/account/recruiter-profile" graphic="company">
                Recruiter Profile
              </LiLink>
              <LiLink
                external
                tabIndex={0}
                graphic="account_circle"
                path="https://accounts.moonlighttoken.com/"
              >
                Moonlight Account
              </LiLink>
              <Li onClick={signOut} graphic="logout">
                Log Out
              </Li>
            </>
          )}
        </Ul>
      </DrawerContent>
    </Drawer>
  );
};

export default MobileDrawer;

import axios from 'axios';

export interface Headers {
  [index: string]: string;
}

const defaultQueryFn = async ({ queryKey }: { queryKey: any }) => {
  // Assert unkown type
  let url, options;
  if (typeof queryKey === 'string') {
    url = queryKey;
    options = {};
  } else {
    url = queryKey[0];
    const headers = queryKey[1] ?? {};

    if (queryKey[2]) {
      const token = await queryKey[2]?.getIdToken();
      headers['Authorization'] = `Bearer ${token}`;
    }

    options = {
      headers: headers,
    };
  }
  const { data } = await axios.get(
    `${process.env.REACT_APP_LEMON_API}${url}`,
    options
  );
  return data;
};

export default defaultQueryFn;

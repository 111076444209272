import React, { useState } from 'react';
import { Token } from 'utils/types';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import Page from 'components/templates/Page';
import Grid, { Inner, Cell } from 'components/templates/Grid';
import Section, { Header } from 'components/templates/Section';
import Ul from 'components/atoms/Ul';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import SearchField from 'components/atoms/SearchField';
import ViewAllJobs from 'components/atoms/ViewAllJobs';
import Timer from 'components/atoms/Timer';
import TokenInfo from 'components/organisms/TokenInfo';
import JobInfo from 'components/organisms/JobInfo';
import { useInfiniteTokenQuery, useInfiniteJobQuery } from 'utils/api/hooks';
import { useQueryKey } from 'utils/hooks';
import Loader from 'components/atoms/Loader';

const TokenHub: React.FunctionComponent = () => {
  const jobsQuery = useInfiniteJobQuery();
  const jobsList = jobsQuery.isSuccess
    ? jobsQuery.data.pages.map((page) => page.tokens).flat()
    : [];
  const mostAwardedQueryKey = useQueryKey('/token-race/token/most-awarded/');
  const mostAwardedQuery = useQuery<Token>(mostAwardedQueryKey);

  const [searchTerm, setSearchTerm] = useState('');
  // This is used to distinguish the initial tokens load from the search ones
  const [searchChanged, setSearchChanged] = useState(false);
  const history = useHistory();

  const tokensQuery = useInfiniteTokenQuery(searchTerm);
  const tokenList = tokensQuery.isSuccess
    ? tokensQuery.data.pages.map((page) => page.tokens).flat()
    : [];

  const token_hub_help = (
    <>
      <h2>Welcome on the Lemonn Token Hub!</h2>
      <p>
        Each week people can promote their favorite tokens by upvoting or
        awarding them.
      </p>
      <ul>
        <li>
          Anyone can upvote any number of tokens. The Trending section show the
          most upvoted ones!
        </li>
        <li>
          To award a token, you have to be logged-in and hold at least 100
          Lemonn Tokens. You can only award one token each week. Awarded tokens
          show a special badge, and the most awarded one is displayed at the top
          of the page.
        </li>
        <li>Awards and upvotes are reset each Monday at 7pm UTC</li>
      </ul>
    </>
  );

  return (
    <Page
      title="Lemonn | Hub"
      error={
        jobsQuery.isError || tokensQuery.isError || mostAwardedQuery.isError
      }
      loading={
        jobsQuery.isLoading ||
        (tokensQuery.isLoading && !searchChanged) ||
        mostAwardedQuery.isLoading
      }
    >
      <Grid>
        <Inner>
          <Cell span={8}>
            <Section>
              <Header
                icon="prize"
                title={['Most Awarded', 'Most Awarded of the week']}
                help={token_hub_help}
              >
                <Timer searchTerm={searchTerm} />
              </Header>
              {mostAwardedQuery.isSuccess && (
                <TokenInfo
                  height={['68px', '88px']}
                  padding={['8px', '8px 12px 8px 8px']}
                  fontSize={['0.9em', '1em']}
                  token={mostAwardedQuery.data}
                  is_most_awarded={true}
                />
              )}
            </Section>
            <Section>
              <Header icon="trending" title="Trending" help={token_hub_help}>
                <SearchField
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchChanged(true);
                    setSearchTerm(e.target.value);
                  }}
                />
              </Header>
              {tokensQuery.isLoading ? (
                <Box display="flex" justifyContent="center" marginTop="50px">
                  <Loader size={100} />
                </Box>
              ) : (
                <Ul style={{ padding: '0' }}>
                  {tokenList.map((token) => (
                    <li key={token.id} style={{ marginBottom: '10px' }}>
                      <TokenInfo
                        height={['56px', '68px']}
                        padding={['8px', '8px 12px 8px 8px']}
                        fontSize={['0.9em', '1em']}
                        token={token}
                        is_most_awarded={
                          mostAwardedQuery.isSuccess &&
                          mostAwardedQuery.data.id === token.id
                        }
                      />
                    </li>
                  ))}
                  {tokenList.length < 15 || !tokensQuery.hasNextPage ? (
                    <Box textAlign="center" marginTop="20px">
                      {tokenList.length === 0 ? (
                        <p>There is no token matching your search query.</p>
                      ) : (
                        !tokensQuery.hasNextPage && (
                          <Box textAlign="center" height="36px" opacity="0.5">
                            <p>No more available results</p>
                          </Box>
                        )
                      )}
                      <Button raised onClick={() => history.push('/add-token')}>
                        Add a Token
                      </Button>
                      {searchTerm !== '' && (
                        <Box display="inline-block" marginLeft="10px">
                          <Button outlined onClick={() => setSearchTerm('')}>
                            Reset Search
                          </Button>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    tokensQuery.hasNextPage &&
                    (tokensQuery.isFetchingNextPage ? (
                      <Box display="flex" justifyContent="center">
                        <Loader size={36} />
                      </Box>
                    ) : (
                      <Box textAlign="center">
                        <Button onClick={() => tokensQuery.fetchNextPage()}>
                          Load More
                        </Button>
                      </Box>
                    ))
                  )}
                </Ul>
              )}
            </Section>
          </Cell>
          <Cell span={4} tablet_span={8}>
            <Section>
              <Header icon="work" title="Hiring Now" />
              <Ul style={{ padding: '0' }}>
                {jobsList.slice(0, 10).map((job) => (
                  <li key={job.id} style={{ marginBottom: '10px' }}>
                    <JobInfo
                      key={job.publisher.name}
                      job={job}
                      padding={'8px 8px 8px 12px'}
                    />
                  </li>
                ))}
              </Ul>
              <ViewAllJobs />
            </Section>
          </Cell>
        </Inner>
      </Grid>
    </Page>
  );
};
export default TokenHub;

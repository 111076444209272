import React, { useState, useRef, useEffect } from 'react';
import { MDCDialog } from '@material/dialog';
import Dialog from './Dialog';

type ModalContext = {
  open: (Content: React.ReactNode) => void;
};
const modalContext = React.createContext({} as ModalContext);

const ModalProvider: React.FunctionComponent = ({ children }) => {
  const [modal, setModal] = useState<React.ReactNode>();
  const modalRef = useRef<HTMLDivElement>(null);
  const modalManagerRef = useRef<MDCDialog | null>(null);

  const open = (Content: React.ReactNode) => {
    setModal(Content);
    modalManagerRef.current && modalManagerRef.current.open();
  };

  useEffect(() => {
    if (modalRef.current) {
      modalManagerRef.current = new MDCDialog(modalRef.current);
    }
    return () => {
      modalManagerRef.current && modalManagerRef.current.destroy();
    };
  }, []);

  return (
    <modalContext.Provider value={{ open }}>
      {children}
      <Dialog ref={modalRef}>{modal}</Dialog>;
    </modalContext.Provider>
  );
};

export const useModal = () => React.useContext(modalContext);

export default ModalProvider;

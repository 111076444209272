import styled from 'styled-components';
import {
  padding,
  PaddingProps,
  margin,
  MarginProps,
  width,
  height,
  fontSize,
  FontSizeProps,
  HeightProps,
  WidthProps,
} from 'styled-system';

export type ContentCardProps = WidthProps &
  HeightProps &
  MarginProps &
  PaddingProps &
  FontSizeProps;
const ContentCard = styled.div<ContentCardProps>`
  ${width}
  ${height}
  ${margin}
  ${padding}
  ${fontSize}
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 10px 0;
  background-color: ${(props) => props.theme.themeBlendingSurface};
  box-sizing: border-box;
`;

ContentCard.defaultProps = {
  width: '100%',
};

export default ContentCard;

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from 'components/atoms/Icon';
import { useInfiniteTokenQuery } from 'utils/api/hooks';
import { useModal } from 'providers/ModalContext';
import { useQuery } from 'react-query';
import { useQueryKey } from 'utils/hooks';
import { Token } from 'utils/types';

const Styled = styled.span`
  display: flex;
  align-items: center;
  background-color: #ffffff11;
  color: #ffffff99;
  border-radius: 10px 0;
  font-size: 0.7em;
  padding: 0 8px;
  height: 30px;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    background-color: #ffffff22;
  }
`;

const TimeText = styled.span`
  font-weight: bolder;
  font-size: 1.3em;
  font-family: monospace;
`;

const ResetText = styled.span`
  @media (max-width: 340px) {
    display: none;
  }
`;

interface TimerProps {
  searchTerm: string;
}

const calculateTimeLeft = () => {
  var dateNow = new Date();
  const targetGetDate =
    dateNow.getUTCDay() === 1
      ? // Monday Case
        dateNow.getUTCHours() >= 19 &&
        // On reset stay 3 seconds on 0
        (dateNow.getUTCSeconds() > 3 || dateNow.getUTCMinutes() > 0)
        ? dateNow.getUTCDate() + 7
        : dateNow.getUTCDate()
      : // Other Weekdays Cases
        dateNow.getUTCDate() + ((7 - dateNow.getUTCDay() + 1) % 7);
  const dateTarget = new Date(
    Date.UTC(
      dateNow.getUTCFullYear(),
      dateNow.getUTCMonth(),
      targetGetDate,
      19,
      0,
      0
    )
  );
  return dateTarget.getTime() - dateNow.getTime();
};

const formatTimeLeft = (timeLeft: number) => {
  if (timeLeft < 0) {
    return '0"';
  }
  const days = Math.floor(timeLeft / 1000 / 60 / 60 / 24);
  const hours = Math.floor((timeLeft / 1000 / 60 / 60) % 24);
  if (days > 0) {
    return `${hours > 12 ? days + 1 : days}d`;
  } else {
    const minutes = Math.floor((timeLeft / 1000 / 60) % 60);
    if (hours > 0) {
      const strMinutes =
        minutes < 10 ? '0' + minutes.toString() : minutes.toString();
      return `${hours}h${strMinutes}`;
    } else {
      const seconds = Math.floor((timeLeft / 1000) % 60);
      if (minutes > 0) {
        const strSeconds =
          seconds < 10 ? '0' + seconds.toString() : seconds.toString();
        return `${minutes}'${strSeconds}"`;
      } else {
        return `${seconds}"`;
      }
    }
  }
};

const Timer: React.FunctionComponent<TimerProps> = ({ searchTerm }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const tokensQuery = useInfiniteTokenQuery(searchTerm);
  const mostAwardedQueryKey = useQueryKey('/token-race/token/most-awarded/');
  const mostAwardedQuery = useQuery<Token>(mostAwardedQueryKey);
  const { open } = useModal();
  useEffect(() => {
    const timer = setTimeout(() => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft(newTimeLeft);
      if (newTimeLeft < 1000) {
        tokensQuery.refetch();
        mostAwardedQuery.refetch();
      }
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  }, [timeLeft, tokensQuery, mostAwardedQuery]);
  return (
    <Styled
      onClick={() =>
        open(
          <div>
            Awards and upvotes are reset each Monday at 7pm UTC
            <div className="mdc-dialog__actions">
              <button
                type="button"
                className="mdc-button mdc-dialog__button"
                data-mdc-dialog-action="discard"
                data-mdc-dialog-initial-focus
              >
                <div className="mdc-button__ripple"></div>
                <span className="mdc-button__label">Close</span>
              </button>
            </div>
          </div>
        )
      }
    >
      <Icon
        width="16px"
        height="16px"
        icon="schedule"
        style={{ marginRight: '4px' }}
      />
      <span>
        <ResetText>Resets in </ResetText>
        <TimeText>{formatTimeLeft(timeLeft)}</TimeText>
      </span>
    </Styled>
  );
};

export default Timer;

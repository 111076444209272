import React from 'react';
import classnames from 'classnames';

type ActionItemProps = {
  drawerToggle?: boolean;
  onClick: () => void;
};
const ActionItem: React.FunctionComponent<ActionItemProps> = ({
  children,
  onClick,
  drawerToggle,
}) => {
  const actionItemClassname = classnames('mdc-top-app-bar__action-item', {
    'mdc-icon-button mdc-top-app-bar__action-item--drawer-toggle': drawerToggle,
  });
  return (
    <div onClick={onClick} className={actionItemClassname}>
      {children}
    </div>
  );
};

export default ActionItem;

import './App.scss';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import AppBar from 'components/organisms/AppBar';
import TokenHub from 'pages/TokenHub';
import AddToken from 'pages/AddToken';
import AddProfile from 'pages/AddProfile';
import AddJob from 'pages/AddJob';
import JobHub from 'pages/JobHub';
import Submissions from 'pages/Submissions';
import MyJobs from 'pages/MyJobs';
import RecruiterProfile from 'pages/RecruiterProfile';
import LoginContext from 'providers/LoginContext';
import ModalProvider from 'providers/ModalContext';
import ScrollToTop from 'utils/ScrollToTop';
import JobPage from 'pages/JobPage';
import TokenPage from 'pages/TokenPage';
import RecruiterPage from 'pages/RecruiterPage';
import About from 'pages/About';

function App() {
  return (
    <LoginContext>
      <ModalProvider>
        <Router>
          <ScrollToTop />
          <AppBar />
          <Switch>
            <Route path="/hub">
              <TokenHub />
            </Route>
            <Route path="/jobs">
              <JobHub />
            </Route>
            <Route path="/account/submissions">
              <Submissions />
            </Route>
            <Route path="/account/jobs">
              <MyJobs />
            </Route>
            <Route path="/account/recruiter-profile">
              <RecruiterProfile />
            </Route>
            <Route path="/add-token">
              <AddToken />
            </Route>
            <Route path="/create-profile">
              <AddProfile />
            </Route>
            <Route path="/post-job/:publisher_id?">
              <AddJob />
            </Route>
            <Route path="/job/:job_id">
              <JobPage />
            </Route>
            <Route path="/token/:token_id">
              <TokenPage />
            </Route>
            <Route path="/recruiter/:recruiter_id">
              <RecruiterPage />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route>
              <Redirect to="/hub" />
            </Route>
          </Switch>
        </Router>
      </ModalProvider>
    </LoginContext>
  );
}

export default App;

import { useState, useCallback } from 'react';
import { useLogin } from 'providers/LoginContext';
export function useLocalStorage<T>(key: string, initialValue: T) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  // We need to wrap it in useCallback so we can use it safely i
  // useEffect like setStoredValue
  const setValue = useCallback(
    (value: T | ((value: T) => T)) => {
      try {
        // Allow value to be a function so we have same API as useState
        // Save state
        setStoredValue(value);
        // Save to local storage
        window.localStorage.setItem(key, JSON.stringify(value));
      } catch (error) {
        // A more advanced implementation would handle the error case
        console.log(error);
      }
    },
    [key]
  );
  return [storedValue, setValue];
}

export const useQueryKey = (url: string) => {
  const { user } = useLogin();
  const queryKey = !!user ? [url, {}, user] : url;
  return queryKey;
};

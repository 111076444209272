import React from 'react';
import styled from 'styled-components';

interface DialogProps {
  children?: React.ReactNode;
}

const Content = styled.div`
  a {
    color: ${(props) => props.theme.themePastelPurple};

    &:hover {
      color: ${(props) => props.theme.themePastelPurple} !important;
    }
  }
`;
const Dialog = React.forwardRef<HTMLDivElement, DialogProps>(
  ({ children }, ref) => {
    return (
      <div ref={ref} className="mdc-dialog">
        <div className="mdc-dialog__container">
          <div
            className="mdc-dialog__surface"
            role="alertdialog"
            aria-modal="true"
            aria-labelledby="my-dialog-title"
            aria-describedby="my-dialog-content"
          >
            <Content className="mdc-dialog__content" id="my-dialog-content">
              {children}
            </Content>
          </div>
        </div>
        <div className="mdc-dialog__scrim"></div>
      </div>
    );
  }
);

export default Dialog;

import React from 'react';
import { Token, Job } from 'utils/types';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Page from 'components/templates/Page';
import Grid, { Inner, Cell } from 'components/templates/Grid';
import Section, { Header } from 'components/templates/Section';
import styled from 'styled-components';
import Box from 'components/atoms/Box';
import { ExpandedLinks } from 'components/molecules/Links';
import Ul from 'components/atoms/Ul';
import JobInfo from 'components/organisms/JobInfo';
import ViewAllJobs from 'components/atoms/ViewAllJobs';

import {
  chart_link,
  contract_link,
  pancake_link,
  ape_link,
  twitter_link,
} from 'utils/links';

type TokenPageRouteParams = {
  token_id: string;
};

const Img = styled.img`
  border-radius: 10px 0;
  object-fit: cover;
  width: 60px;
  height: 60px;
  margin-right: 16px;
`;

const TokenTitle = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  line-height: 1.9em;
`;

const EllipsisText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Name = styled(EllipsisText)`
  font-size: 1.6em;
  font-weight: bolder;
`;

const Symbol = styled(EllipsisText)`
  opacity: 0.6;
  font-size: 1.2em;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;
`;

const TokenPage: React.FunctionComponent = () => {
  const params = useParams<TokenPageRouteParams>();
  const tokenQuery = useQuery<Token>(`/token-race/token/${params.token_id}/`);
  const jobsQuery = useQuery<Job[]>(
    `/token-race/token/${params.token_id}/jobs/`
  );

  return (
    <Page
      title={
        tokenQuery.isSuccess ? `Lemonn | ${tokenQuery.data.name}` : 'Lemonn'
      }
      error={tokenQuery.isError}
      loading={tokenQuery.isLoading}
    >
      <Grid>
        <Inner>
          <Cell solo_column>
            {tokenQuery.isSuccess && (
              <>
                <Box mt={['12px', '20px']} mb={['12px', '16px']}>
                  <TokenTitle>
                    <Img
                      src={tokenQuery.data.image}
                      alt={tokenQuery.data.name}
                    />
                    <TextContainer>
                      <Name>{tokenQuery.data.name}</Name>
                      <Symbol>{tokenQuery.data.symbol}</Symbol>
                    </TextContainer>
                  </TokenTitle>
                </Box>
                <Box mb={['8px', '12px']}>
                  <ExpandedLinks
                    links={[
                      {
                        name: 'Website',
                        icon: 'world',
                        url: tokenQuery.data.website,
                      },
                      {
                        name: 'Telegram',
                        icon: 'telegram',
                        url: tokenQuery.data.telegram_link,
                      },
                      {
                        name: 'Twitter',
                        icon: 'twitter',
                        url: twitter_link(tokenQuery.data.twitter_id),
                      },
                      {
                        name: 'Contract',
                        icon: 'bscscan',
                        url: contract_link(tokenQuery.data.contract_address),
                      },
                      {
                        name: 'Chart',
                        icon: 'poo',
                        url: chart_link(tokenQuery.data.contract_address),
                      },
                      {
                        name: 'ApeSwap',
                        icon: 'apeswap',
                        url: ape_link(tokenQuery.data.contract_address),
                      },
                      {
                        name: 'PancakeSwap',
                        icon: 'pancake',
                        url: pancake_link(tokenQuery.data.contract_address),
                      },
                    ]}
                  />
                </Box>
                <Section>
                  <Header icon="work" title="Open positions for this token" />
                  {jobsQuery.isSuccess && (
                    <Ul style={{ padding: '0', marginBottom: '20px' }}>
                      {jobsQuery.data.map((job) => (
                        <li key={job.id} style={{ marginBottom: '10px' }}>
                          <JobInfo job={job} />
                        </li>
                      ))}
                      {jobsQuery.data.length === 0 && (
                        <Box textAlign="center">
                          <p>There is currently no open jobs for this token.</p>
                        </Box>
                      )}
                    </Ul>
                  )}
                  <ViewAllJobs />
                </Section>
              </>
            )}
          </Cell>
        </Inner>
      </Grid>
    </Page>
  );
};
export default TokenPage;

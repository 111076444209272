import React from 'react';
import Icon, { IconType } from 'components/atoms/Icon';
import Box from 'components/atoms/Box';
import styled from 'styled-components';
import { useModal } from 'providers/ModalContext';

interface HeaderProps {
  title: String | [String, String];
  icon: IconType;
  help?: JSX.Element;
}

const HeaderContainer = styled.div`
  display: flex;
  height: 36px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;

  h3 {
    margin: 0;
    display: flex;
    color: ${(props) => props.theme.themePastelPurple};
    font-weight: 300;
    font-family: Roboto;
    align-items: center;
    flex-shrink: 0;
  }
`;

const HelpIcon = styled(Icon)`
  padding: 8px;
  cursor: pointer;
  color: ${(props) => props.theme.themeGreyedPurple};
  opacity: 0.7;

  &:hover {
    opacity: 1;
    color: ${(props) => props.theme.themeOrange};
  }
`;

const Header: React.FunctionComponent<HeaderProps> = ({
  title,
  icon,
  children,
  help,
  ...attributes
}) => {
  const { open } = useModal();
  return (
    <HeaderContainer {...attributes}>
      <h3>
        <Icon width="1em" height="1em" marginRight="8px" icon={icon} />
        {typeof title === 'string' ? (
          <Box>{title}</Box>
        ) : (
          <>
            <Box display={['initial', 'none']}>{title[0]}</Box>
            <Box display={['none', 'initial']}>{title[1]}</Box>
          </>
        )}
        {help && (
          <HelpIcon
            width="1em"
            height="1em"
            icon="help_outline"
            onClick={() =>
              open(
                <div>
                  {help}
                  <div className="mdc-dialog__actions">
                    <button
                      type="button"
                      className="mdc-button mdc-dialog__button"
                      data-mdc-dialog-action="discard"
                      data-mdc-dialog-initial-focus
                    >
                      <div className="mdc-button__ripple"></div>
                      <span className="mdc-button__label">Close</span>
                    </button>
                  </div>
                </div>
              )
            }
          />
        )}
      </h3>
      {children}
    </HeaderContainer>
  );
};

export default Header;

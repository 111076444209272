import React from 'react';
import FormError from 'components/atoms/FormError';

import { JobFormValues } from './AddJobForm';
import Box from 'components/atoms/Box';
import { useFormikContext, ErrorMessage } from 'formik';
import TextField from 'components/atoms/TextField';
import TextAreaField from 'components/atoms/TextAreaField';
import Select, { SelectOption } from 'components/atoms/Select';
interface AddJobFormFieldProps {
  name: keyof Omit<JobFormValues, 'image'>;
  label: string;
  textarea_length?: number;
  options?: SelectOption[];
}

const AddJobFormField: React.FunctionComponent<AddJobFormFieldProps> = ({
  name,
  label,
  textarea_length,
  options,
}) => {
  const {
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  } = useFormikContext<JobFormValues>();
  return (
    <Box mb="16px">
      {textarea_length ? (
        <TextAreaField
          width="100%"
          label={label}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name]}
          invalid={touched[name] && !!errors[name]}
          maxLength={textarea_length}
        />
      ) : options ? (
        <Select
          width="100%"
          name={name}
          label={label}
          options={options}
          invalid={touched[name] && !!errors[name]}
          // onChange event doesn't fire on hidden inputs
          customOnChange={(value) => {
            setFieldValue(name, value);
            setFieldTouched(name, true);
          }}
        />
      ) : (
        <TextField
          width="100%"
          label={label}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name]}
          filled={!textarea_length}
          invalid={touched[name] && !!errors[name]}
          textarea_length={textarea_length}
        />
      )}
      <ErrorMessage name={name}>
        {(msg: string) => <FormError>{msg}</FormError>}
      </ErrorMessage>
    </Box>
  );
};

export default AddJobFormField;

import React from 'react';
import FormError from 'components/atoms/FormError';

import { ProfileFormValues } from './AddProfileForm';
import Box from 'components/atoms/Box';
import TextField from 'components/atoms/TextField';
import { useFormikContext, ErrorMessage } from 'formik';
interface AddProfileFormFieldProps {
  name: keyof Omit<ProfileFormValues, 'image'>;
  label: string;
  icon?: React.ReactNode;
}

const AddProfileFormField: React.FunctionComponent<AddProfileFormFieldProps> =
  ({ name, label, icon }) => {
    const { handleChange, values, touched, errors, handleBlur } =
      useFormikContext<ProfileFormValues>();
    return (
      <Box mb="16px">
        <TextField
          width="100%"
          label={label}
          leadingIcon={icon}
          name={name}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values[name]}
          filled
          invalid={touched[name] && !!errors[name]}
        />
        <ErrorMessage name={name}>
          {(msg: string) => <FormError>{msg}</FormError>}
        </ErrorMessage>
      </Box>
    );
  };

export default AddProfileFormField;

import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';
import styled from 'styled-components';
import { layout, LayoutProps } from 'styled-system';
import LiGraphic from './LiGraphic';
import { MDCRipple } from '@material/ripple';
import { IconType } from 'components/atoms/Icon';
import Ripple from './Ripple';

export type BaseLiProps = {
  flexEnd?: boolean;
};
export type LiProps = {
  gradient?: boolean;
  activated?: boolean;
  title?: string;
  graphic?: IconType;
  className?: string;
  rtl?: boolean;
} & BaseLiProps &
  LayoutProps &
  React.ComponentPropsWithoutRef<'li'>;

const BaseLi = styled.li<BaseLiProps>`
  display: flex
  justify-content: ${(props) => (props.flexEnd ? 'flex-end' : 'flex-start')}
`;

const Li: React.FunctionComponent<LiProps> = ({
  children,
  graphic,
  title,
  className,
  gradient,
  activated,
  rtl,
  ...baseLiProps
}) => {
  const LiClassName = classnames('mdc-list-item', className, {
    'mdc-list-item--gradient': gradient,
    'mdc-list-item--activated': activated,
  });
  const rippleRef = useRef<HTMLElement>(null);
  const rippleManager = useRef<MDCRipple>();

  useEffect(() => {
    if (rippleRef.current) {
      rippleManager.current = new MDCRipple(rippleRef.current);
    }
    return () => rippleManager.current?.destroy();
  }, []);

  return (
    // @ts-ignore
    <BaseLi {...baseLiProps} className={LiClassName}>
      <Ripple ref={rippleRef} />
      {graphic && <LiGraphic icon={graphic} />}
      {rtl && children}
      {title && <span className="mdc-list-item__text">{title}</span>}
      {!rtl && children}
    </BaseLi>
  );
};

export default styled(Li)<LiProps>`
  ${layout}
`;

import React, { useRef, useEffect } from 'react';
import { width, height, WidthProps } from 'styled-system';
import styled from 'styled-components';

import { MDCTextField } from '@material/textfield';
import { MDCRipple } from '@material/ripple';

interface TextAreaFieldInterface
  extends React.ComponentPropsWithoutRef<'textarea'> {
  label: string;
  className?: string;
  invalid?: boolean;
  maxLength: number;
}

export type TextAreaFieldProps = TextAreaFieldInterface & WidthProps;

const TextAreaField: React.FunctionComponent<TextAreaFieldProps> = ({
  label,
  className,
  invalid,
  maxLength,
  ...textAreaAttributes
}) => {
  // Text Field
  const textFieldRef = useRef<HTMLLabelElement>(null);
  const textFieldManager = useRef<MDCTextField | null>(null);

  // Text Field
  const rippleRef = useRef<HTMLSpanElement>(null);
  const rippleManager = useRef<MDCRipple | null>(null);

  useEffect(() => {
    textFieldManager.current = new MDCTextField(textFieldRef.current!);
    rippleManager.current = new MDCRipple(rippleRef.current!);
    return () => {
      textFieldManager.current!.destroy();
      rippleManager.current!.destroy();
    };
  }, []);

  useEffect(() => {
    if (textFieldManager.current) {
      textFieldManager.current.valid = !invalid;
    }
  });

  return (
    <label
      ref={textFieldRef}
      className="mdc-text-field mdc-text-field--outlined mdc-text-field--textarea mdc-text-field--with-internal-counter"
      // Temporary fix, can't manage to make widthprops work
      style={{ width: '100%' }}
    >
      <span ref={rippleRef} className="mdc-text-field__ripple"></span>
      <span className="mdc-notched-outline mdc-notched-outline--upgraded">
        <span className="mdc-notched-outline__leading"></span>
        <span className="mdc-notched-outline__notch">
          <span className="mdc-floating-label">{label}</span>
        </span>
        <span className="mdc-notched-outline__trailing"></span>
      </span>
      <span className="mdc-text-field__resizer">
        <textarea
          className="mdc-text-field__input"
          aria-labelledby="my-label-id"
          rows={8}
          cols={40}
          maxLength={maxLength}
          {...textAreaAttributes}
        ></textarea>
        <span className="mdc-text-field-character-counter">
          0 / {maxLength}
        </span>
      </span>
    </label>
  );
};

export default styled(TextAreaField)<TextAreaFieldProps>`
  ${width}
  ${height}
`;

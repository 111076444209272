import { Token, Job } from 'utils/types';
import axios from 'axios';
import { useInfiniteQuery } from 'react-query';
import { useLogin } from 'providers/LoginContext';

const fetchTokens = async ({ queryKey, pageParam }: any, search?: string) => {
  const headers = {} as any;
  const cursor = pageParam ?? 0;

  if (queryKey[2]) {
    const token = await queryKey[2]?.getIdToken();
    headers['Authorization'] = `Bearer ${token}`;
  }

  const queryString =
    `${process.env.REACT_APP_LEMON_API}/token-race/token/?limit=15&cursor=${cursor}` +
    (!!search ? `&search=${search}` : '');
  const { data } = await axios.get(queryString, {
    headers: headers,
  });
  return { tokens: data, nextCursor: data.length > 0 ? cursor + 1 : undefined };
};

export const useInfiniteTokenQuery = (search?: string) => {
  const { user } = useLogin();
  const url = '/token-race/token/';
  const queryKey = !!user
    ? [url, { search: search }, user]
    : [url, { search: search }];

  const tokensQuery = useInfiniteQuery<{ tokens: Token[]; nextCursor: number }>(
    queryKey,
    ({ queryKey, pageParam }) => fetchTokens({ queryKey, pageParam }, search),
    {
      getNextPageParam: (lastPage, _allPages) => lastPage.nextCursor,
    }
  );
  return tokensQuery;
};

const fetchJobs = async (
  { queryKey, pageParam }: any,
  type?: string,
  field?: string
) => {
  const headers = {} as any;
  const cursor = pageParam ?? 0;

  if (queryKey[2]) {
    const token = await queryKey[2]?.getIdToken();
    headers['Authorization'] = `Bearer ${token}`;
  }

  const queryString =
    `${process.env.REACT_APP_LEMON_API}/jobs/job/open/?limit=10&cursor=${cursor}` +
    (!!type ? `&type=${type}` : '') +
    (!!field ? `&field=${field}` : '');
  const { data } = await axios.get(queryString, {
    headers: headers,
  });
  return { tokens: data, nextCursor: data.length > 0 ? cursor + 1 : undefined };
};

export const useInfiniteJobQuery = (type?: string, field?: string) => {
  const { user } = useLogin();
  const url = '/jobs/job/open/';
  const queryKey = !!user
    ? [url, { type: type, field: field }, user]
    : [url, { type: type, field: field }];

  const tokensQuery = useInfiniteQuery<{ tokens: Job[]; nextCursor: number }>(
    queryKey,
    ({ queryKey, pageParam }) =>
      fetchJobs({ queryKey, pageParam }, type, field),
    {
      getNextPageParam: (lastPage, _allPages) => lastPage.nextCursor,
    }
  );
  return tokensQuery;
};

import React from 'react';
import styled from 'styled-components';
import Page from 'components/templates/Page';
import Button from 'components/atoms/Button';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Lemonn } from 'components/icons/lemonn.svg';

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 700px;
  padding: 56px 10px 0 10px;
  min-height: calc(100vh - 56px);
  margin: auto;

  a {
    color: ${(props) => props.theme.themePastelPurple};

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Socials = styled.div`
  svg {
    fill: white;
    height: 25px;
    margin: 10px 15px;
  }
  a:hover {
    svg {
      fill: ${(props) => props.theme.themeOrange};
    }
  }
`;

const About: React.FunctionComponent = () => {
  const history = useHistory();
  return (
    <Page title="Lemonn | About">
      <AboutContainer>
        <a href="https://lemonn.io" target="_blank" rel="noreferrer">
          <Lemonn style={{ height: '80px' }} />
        </a>
        <h1> About Lemonn </h1>
        <p>
          <b>Everyone can work on BSC. Why not you?</b>
          <br />
          The first job searching platform tailored to BSC: Remote, flexible,
          easy.
          <br />
          Holding Lemonn Token offers premium advantages for job seekers and
          recruiters.
        </p>
        <Socials>
          <a href="https://lemonn.io" target="_blank" rel="noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 209.281 209.281"
            >
              <path d="M203.456 139.065c3.768-10.786 5.824-22.369 5.824-34.425s-2.056-23.639-5.824-34.425c-.092-.324-.201-.64-.333-.944C188.589 28.926 149.932 0 104.641 0S20.692 28.926 6.159 69.271c-.132.305-.242.62-.333.944C2.058 81.001.002 92.584.002 104.64s2.056 23.639 5.824 34.425c.092.324.201.64.333.944 14.534 40.346 53.191 69.271 98.482 69.271s83.948-28.926 98.482-69.271c.132-.304.241-.619.333-.944zm-98.815 55.216c-3.985 0-10.41-7.212-15.78-23.324-2.592-7.775-4.667-16.713-6.179-26.436H126.6c-1.512 9.723-3.587 18.66-6.178 26.436-5.371 16.112-11.796 23.324-15.781 23.324zm-23.779-64.76c-.721-7.998-1.102-16.342-1.102-24.881s.381-16.883 1.102-24.881h47.557c.721 7.998 1.102 16.342 1.102 24.881s-.381 16.883-1.102 24.881H80.862zm-65.861-24.88c0-8.63 1.229-16.978 3.516-24.881h47.3c-.701 8.163-1.057 16.529-1.057 24.881s.355 16.718 1.057 24.881h-47.3c-2.287-7.904-3.516-16.251-3.516-24.881zM104.641 15c3.985 0 10.411 7.212 15.781 23.324 2.591 7.775 4.667 16.713 6.178 26.435H82.681c1.512-9.723 3.587-18.66 6.179-26.435C94.231 22.212 100.656 15 104.641 15zm38.823 64.76h47.3c2.287 7.903 3.516 16.251 3.516 24.881s-1.229 16.978-3.516 24.881h-47.3c.701-8.163 1.057-16.529 1.057-24.881s-.356-16.718-1.057-24.881zm41.439-15h-43.16c-2.668-18.397-7.245-34.902-13.666-46.644 24.895 6.749 45.52 23.98 56.826 46.644zM81.204 18.115C74.783 29.857 70.206 46.362 67.538 64.76h-43.16c11.307-22.664 31.931-39.895 56.826-46.645zM24.378 144.521h43.16c2.668 18.397 7.245 34.902 13.666 46.645-24.895-6.75-45.519-23.98-56.826-46.645zm103.699 46.645c6.421-11.742 10.998-28.247 13.666-46.645h43.16c-11.306 22.665-31.931 39.895-56.826 46.645z" />
            </svg>
          </a>
          <a
            href="https://twitter.com/LemonnApp"
            target="_blank"
            rel="noreferrer"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z" />
            </svg>
          </a>
          <a href="https://t.me/LemonnToken" target="_blank" rel="noreferrer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="18 62.3 377.7 377.7"
            >
              <path d="M371 93.7L26.6 228c-11.2 4.3-10.5 20.4 1 23.7l87.5 25.8 32.7 103.6c3.4 10.8 17.1 14.1 25.1 6l45.2-46.2 88.8 65.2c10.9 8 26.3 2.1 29.1-11.1l58.8-281.1c2.8-13.8-10.7-25.3-23.8-20.2zm-43.2 62.4l-160 141.5c-1.6 1.4-2.6 3.4-2.8 5.5l-6.2 54.8c-.2 1.8-2.7 2-3.2.3l-25.3-81.7c-1.2-3.7.4-7.8 3.7-9.9l188.9-117.2c4.3-2.6 8.7 3.4 4.9 6.7z" />
            </svg>
          </a>
        </Socials>
        <p>
          Lemonn is part of the{' '}
          <a href="https://moonlighttoken.com" target="_blank" rel="noreferrer">
            Moonlight
          </a>{' '}
          incubation program.
        </p>
        <Button raised onClick={() => history.push('/')}>
          Go Back Home
        </Button>
      </AboutContainer>
    </Page>
  );
};
export default About;

import React, { useContext, useEffect, useState } from 'react';
import firebase, { signIn } from 'utils/auth';

type ContextPropsLoaded = {
  user: firebase.User | null;
  isLoading: false;
};

type ContextPropsLoading = {
  user: undefined;
  isLoading: true;
};

type AuthStateProps = ContextPropsLoading | ContextPropsLoaded;
type ContextProps = AuthStateProps;
const context = React.createContext<ContextProps>({
  user: undefined,
  isLoading: true,
} as ContextProps);

interface LoginContextProps {
  children: React.ReactNode;
}
const LoginContext: React.FunctionComponent<LoginContextProps> = ({
  children,
}: LoginContextProps) => {
  const [authState, setAuthState] = useState<AuthStateProps>({
    user: undefined,
    isLoading: true,
  });

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (recievedUser) => {
      if (recievedUser) {
        setAuthState({
          user: recievedUser,
          isLoading: false,
        });
      } else {
        // The user is not logged in

        // Check if the is user is being redirected from
        // The Moonlight auth server with an identity Token
        const url = new URL(window.location.href);
        const token = url.searchParams.get('token');
        if (token) {
          // If so we can authenticate the user
          signIn(token);
          // Remove the token to clean the url aspect and to avoid to re-login
          // the user if he manually logs out.
          url.searchParams.delete('token');
          window.history.replaceState({}, document.title, String(url));
        } else {
          setAuthState({ user: null, isLoading: false });
        }
      }
    });
  }, [setAuthState]);

  return (
    <context.Provider value={{ ...authState }}>{children}</context.Provider>
  );
};

export const useLogin = () => useContext(context);
export default LoginContext;

import React, { useRef } from 'react';
import { LayoutProps } from 'styled-system';
import Icon, { FontIcon } from 'components/atoms/Icon';

import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import Menu, { useMenu } from 'components/atoms/Menu';
import Ul from 'components/atoms/Ul';
import LiLink from 'components/atoms/LiLink';
import Li from 'components/atoms/Li';

import { signOut } from 'utils/auth';

const LoggedInMenu: React.FunctionComponent<LayoutProps> = (layoutProps) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const { menuManager } = useMenu(menuRef);
  return (
    <Box {...layoutProps} dir="rtl" className="mdc-menu-surface--anchor">
      <Button
        id="menubutton"
        outlined
        onClick={() => {
          if (menuManager) {
            menuManager.toggle();
          }
        }}
      >
        <FontIcon
          icon="expand_more"
          style={{
            display: 'inline-block',
            width: '1.3em',
            height: '1.3em',
            verticalAlign: 'top',
            marginLeft: '4px',
            marginRight: '-4px',
          }}
        />
        Account
      </Button>
      <Menu ref={menuRef} width="210px">
        <Ul>
          <LiLink
            path="/account/submissions"
            flexEnd
            rtl
            title="My Submissions"
          >
            <Icon width="20px" height="20px" icon="verified" />
          </LiLink>
          <LiLink path="/account/jobs" flexEnd rtl title="My Job Offers">
            <Icon width="20px" height="20px" icon="person_search" />
          </LiLink>
          <LiLink path="/post-job" flexEnd rtl title="Post a Job">
            <Icon width="20px" height="20px" icon="group_add" />
          </LiLink>
          <LiLink
            path="/account/recruiter-profile"
            flexEnd
            rtl
            title="Recruiter Profile"
          >
            <Icon width="20px" height="20px" icon="company" />
          </LiLink>
          <LiLink
            flexEnd
            path="https://accounts.moonlighttoken.com/"
            title="Moonlight Account"
            rtl
            external
          >
            <Icon width="20px" height="20px" icon="account_circle" />
          </LiLink>
          <Li onClick={signOut} flexEnd rtl title="Log Out">
            <Icon width="20px" height="20px" icon="logout" />
          </Li>
        </Ul>
      </Menu>
    </Box>
  );
};

export default LoggedInMenu;

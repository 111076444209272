import React from 'react';
import styled from 'styled-components';
import AdaptiveSquareImage from 'components/atoms/AdaptiveSquareImage';
import { NavLink } from 'react-router-dom';

import {
  margin,
  width,
  height,
  MarginProps,
  HeightProps,
  WidthProps,
} from 'styled-system';

type TokenTitleProps = {
  imgUrl: string;
  name: string;
  symbol?: string;
  link?: string;
} & MarginProps &
  HeightProps &
  WidthProps;

const EllipsisText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Name = styled(EllipsisText)`
  transition: color 0.2s ease;
`;

const Symbol = styled(EllipsisText)`
  opacity: 0.6;
  font-size: 0.9em;
`;
const Img = styled(AdaptiveSquareImage)`
  margin-right: 12px;
`;

type NavLinkProps = React.ComponentProps<typeof NavLink>;

const A = styled(NavLink)<NavLinkProps>`
  ${width}
  ${height}
  ${margin}
  display: flex;
  align-items: center;
  overflow: hidden;
  line-height: 1.3em;
  &:hover {
    .content-card__text--hover-higlight {
      color: ${(props) => props.theme.themeOrange};
    }
  }
`;

const NoA = styled.div`
  ${width}
  ${height}
  ${margin}
  display: flex;
  align-items: center;
  overflow: hidden;
  line-height: 1.3em;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;
`;

const TokenTitle: React.FunctionComponent<TokenTitleProps> = ({
  imgUrl,
  name,
  symbol,
  link,
  ...attributes
}) => {
  const content = (
    <>
      <Img src={imgUrl} alt={name} />
      <TextContainer>
        <Name className="content-card__text--hover-higlight">{name}</Name>
        {symbol && <Symbol>{symbol}</Symbol>}
      </TextContainer>
    </>
  );
  return link ? (
    <A {...attributes} to={link}>
      {content}
    </A>
  ) : (
    <NoA {...attributes}>{content}</NoA>
  );
};

export default TokenTitle;

import React, { useRef, useEffect } from 'react';
import { width, height } from 'styled-system';
import styled from 'styled-components';
import classNames from 'classnames';

import { MDCTextField } from '@material/textfield';
import { MDCRipple } from '@material/ripple';

export interface TextFieldProps
  extends React.ComponentPropsWithoutRef<'input'> {
  label: string;
  className?: string;
  invalid?: boolean;
  trailingIcon?: React.ReactNode;
  leadingIcon?: React.ReactNode;
  filled?: boolean;
  outlined?: boolean;
  textarea_length?: number;
}

const TextFieldIcon = styled.span`
  font-size: 1.2em;
`;

const TextField: React.FunctionComponent<TextFieldProps> = ({
  label,
  trailingIcon,
  leadingIcon,
  className,
  invalid,
  filled,
  outlined,
  textarea_length,
  ...inputAttributes
}) => {
  // Text Field
  const textFieldRef = useRef<HTMLLabelElement>(null);
  const textFieldManager = useRef<MDCTextField | null>(null);

  // Text Field
  const rippleRef = useRef<HTMLSpanElement>(null);
  const rippleManager = useRef<MDCRipple | null>(null);

  useEffect(() => {
    textFieldManager.current = new MDCTextField(textFieldRef.current!);
    rippleManager.current = new MDCRipple(rippleRef.current!);
    return () => {
      textFieldManager.current!.destroy();
      rippleManager.current!.destroy();
    };
  }, []);

  useEffect(() => {
    if (textFieldManager.current) {
      textFieldManager.current.valid = !invalid;
    }
  });

  const textFieldClassName = classNames('mdc-text-field', className, {
    'mdc-text-field--filled': filled,
    'mdc-text-field--outlined': outlined,
    'mdc-text-field--with-leading-icon': leadingIcon !== undefined,
    'mdc-text-field--with-trailing-icon': trailingIcon !== undefined,
    'mdc-text-field--outlined mdc-text-field--textarea mdc-text-field--with-internal-counter':
      textarea_length !== undefined,
  });

  return (
    <label ref={textFieldRef} className={textFieldClassName}>
      <span ref={rippleRef} className="mdc-text-field__ripple"></span>
      {filled && (
        <span className="mdc-floating-label" id="my-label-id">
          {label}
        </span>
      )}
      {(outlined || textarea_length) && (
        <>
          <span className="mdc-notched-outline mdc-notched-outline--upgraded">
            <span className="mdc-notched-outline__leading"></span>
            <span className="mdc-notched-outline__notch">
              <span className="mdc-floating-label">{label}</span>
            </span>
            <span className="mdc-notched-outline__trailing"></span>
          </span>
          {textarea_length && (
            <span className="mdc-text-field__resizer">
              <textarea
                className="mdc-text-field__input"
                aria-labelledby="my-label-id"
                rows={8}
                cols={40}
                maxLength={textarea_length}
              ></textarea>
              <span className="mdc-text-field-character-counter">
                0 / {textarea_length}
              </span>
            </span>
          )}
        </>
      )}

      {leadingIcon && (
        <TextFieldIcon className="mdc-text-field__icon mdc-text-field__icon--trailing">
          {leadingIcon}
        </TextFieldIcon>
      )}
      {!textarea_length && (
        <input
          {...inputAttributes}
          className="mdc-text-field__input"
          aria-labelledby="my-label-id"
        />
      )}
      {trailingIcon && (
        <TextFieldIcon
          className="mdc-text-field__icon mdc-text-field__icon--trailing"
          style={{ marginRight: '-8px' }}
        >
          {trailingIcon}
        </TextFieldIcon>
      )}
      {!outlined && <span className="mdc-line-ripple"></span>}
    </label>
  );
};

export default styled(TextField)<TextFieldProps>`
  ${width}
  ${height}
`;

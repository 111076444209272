import React from 'react';
import { Job } from 'utils/types';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Page from 'components/templates/Page';
import Grid, { Inner, Cell } from 'components/templates/Grid';
import Section, { Header } from 'components/templates/Section';
import PublisherInfo from 'components/organisms/PublisherInfo';
import JobInfo from 'components/organisms/JobInfo';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import { displayElapsedTime } from 'utils/format';
import Badge from 'components/atoms/Badge';
import Box from 'components/atoms/Box';
import JobStatus from 'components/atoms/JobStatus';
import ViewAllJobs from 'components/atoms/ViewAllJobs';
import Ul from 'components/atoms/Ul';

type JobPageRouteParams = {
  job_id: string;
};

const CreatedAt = styled.div`
  text-align: center;
  opacity: 0.6;
  font-style: italic;
`;
const Description = styled.div`
  white-space: pre-wrap;
  background-color: ${(props) => props.theme.themeBlendingSurface};
  border-radius: 8px;
  padding: 12px;
  line-height: 1.5em;
`;
const ClosedMessage = styled.div`
  text-align: center;
  color: ${(props) => props.theme.colors.error};
  margin-top: 12px;
`;

const JobPage: React.FunctionComponent = () => {
  const params = useParams<JobPageRouteParams>();
  const jobQuery = useQuery<Job>(`/jobs/job/${params.job_id}/`);
  const relatedJobsQuery = useQuery<Job[]>(
    `/jobs/job/${params.job_id}/related-jobs/`
  );

  return (
    <Page
      title={jobQuery.isSuccess ? `Lemonn | ${jobQuery.data.title}` : 'Lemonn'}
      error={jobQuery.isError || relatedJobsQuery.isError}
      loading={jobQuery.isLoading || relatedJobsQuery.isLoading}
    >
      <Grid>
        <Inner>
          {jobQuery.isSuccess && (
            <>
              <Cell span={8}>
                <h1>{jobQuery.data.title}</h1>
                <Box
                  display="flex"
                  alignItems={['flex-start', 'flex-end']}
                  mb="16px"
                  flexDirection={['column', 'row']}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    mr={['0', '8px']}
                    mb={['8px', '0']}
                    fontSize="1.2em"
                  >
                    <Box mr="8px">
                      <JobStatus closed={jobQuery.data.closed} />
                    </Box>
                    <Badge label={jobQuery.data.position_type} />
                    <Badge label={jobQuery.data.field} />
                  </Box>
                  <CreatedAt>
                    Posted {displayElapsedTime(jobQuery.data.created_at)}
                  </CreatedAt>
                </Box>
                <Description>{jobQuery.data.description}</Description>
                {jobQuery.data.closed ? (
                  <ClosedMessage>This position is now closed.</ClosedMessage>
                ) : (
                  <a
                    href={
                      (jobQuery.data.contact.match(/^(\S+@\S+\.\S+)$/)
                        ? 'mailto:'
                        : '') + jobQuery.data.contact
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button
                      raised
                      style={{
                        margin: 'auto',
                        display: 'block',
                        marginTop: '20px',
                      }}
                    >
                      Apply
                    </Button>
                  </a>
                )}
              </Cell>
              <Cell tablet_span={8} desktop_span={4}>
                <Section>
                  <Header icon="company" title="Posted by" />
                  <PublisherInfo
                    publisher={jobQuery.data.publisher}
                    height={['56px', '68px']}
                  />
                </Section>
                <Section>
                  <Section>
                    <Header icon="work" title="Similar Jobs" />
                    {relatedJobsQuery.isSuccess && (
                      <Ul style={{ padding: '0' }}>
                        {relatedJobsQuery.data.map((job) => (
                          <li key={job.id} style={{ marginBottom: '10px' }}>
                            <JobInfo job={job} />
                          </li>
                        ))}
                        {relatedJobsQuery.data.length === 0 && (
                          <Box textAlign="center">
                            <p>
                              There is no open jobs similar to this position.
                            </p>
                          </Box>
                        )}
                      </Ul>
                    )}
                    <ViewAllJobs />
                  </Section>
                </Section>
              </Cell>
            </>
          )}
        </Inner>
      </Grid>
    </Page>
  );
};
export default JobPage;

import React from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { useLogin } from 'providers/LoginContext';
import AddJobFormField from './AddJobFormField';
import { Formik, FormikProps, FormikHelpers } from 'formik';
import * as yup from 'yup';
import Button from 'components/atoms/Button';
import Box from 'components/atoms/Box';
import { useHistory } from 'react-router';
import { useModal } from 'providers/ModalContext';

const validationSchema = yup.object().shape({
  title: yup.string().required(),
  field: yup.string().required(),
  position_type: yup.string().required(),
  contact: yup
    .string()
    .matches(
      /^(https?:\/\/\S+\.\S+|\S+@\S+\.\S+)$/,
      'This is not a valid website link or email address'
    )
    .required(),
  description: yup.string().required(),
});

export interface JobFormValues {
  title: string;
  field: string;
  position_type: string;
  description: string;
  contact: string;
  publisher_id: string;
}

const Info = styled.p`
  font-style: italic;
  opacity: 0.8;
  font-size: 0.9em;
`;

type AddJobFormProps = {
  publisher_id: string;
};

const AddJobForm: React.FunctionComponent<AddJobFormProps> = ({
  publisher_id,
}) => {
  const { user } = useLogin();
  const history = useHistory();
  const { open } = useModal();
  const submitProfile = async (
    values: JobFormValues,
    formikBag: FormikHelpers<JobFormValues>
  ) => {
    if (!user) {
      throw new Error('Missing user');
    }
    const formData = new FormData();
    formData.append('title', values.title);
    formData.append('field', values.field);
    formData.append('position_type', values.position_type);
    formData.append('description', values.description);
    formData.append('contact', values.contact);
    formData.append('publisher_id', values.publisher_id);

    const idToken = await user.getIdToken();

    await axios.post(`${process.env.REACT_APP_LEMON_API}/jobs/job/`, formData, {
      headers: {
        Authorization: `Bearer ${idToken}`,
        'Content-Type': 'multipart/form-data',
      },
    });
    history.push('/account/submissions');
    open(
      <div>
        <b>You have successfully submitted a new Job offer!</b>
        <br />
        <br />
        Our team will review it as soon as possible, this can take a few days.
        You can check its review status in your submissions page.
        <div className="mdc-dialog__actions">
          <button
            type="button"
            className="mdc-button mdc-dialog__button"
            data-mdc-dialog-action="discard"
            data-mdc-dialog-initial-focus
          >
            <div className="mdc-button__ripple"></div>
            <span className="mdc-button__label">Close</span>
          </button>
        </div>
      </div>
    );
  };
  return (
    <Formik
      onSubmit={submitProfile}
      validationSchema={validationSchema}
      initialValues={{
        title: '',
        field: '',
        position_type: '',
        description: '',
        contact: '',
        publisher_id: publisher_id,
      }}
    >
      {({ handleSubmit, isSubmitting }: FormikProps<JobFormValues>) => (
        <form onSubmit={handleSubmit}>
          <h3> Job Details </h3>
          <Info>All the fields are required.</Info>
          <AddJobFormField name="title" label="Job Title" />
          <AddJobFormField
            name="field"
            label="Job Field"
            options={[
              { value: 'growth', label: 'Growth' },
              { value: 'technology', label: 'Technology' },
              { value: 'art', label: 'Art & Design' },
              { value: 'community', label: 'Community Relations' },
              { value: 'content', label: 'Content' },
              { value: 'other', label: 'Other' },
            ]}
          />
          <AddJobFormField
            name="position_type"
            label="Position Type"
            options={[
              { value: 'full_time', label: 'Full Time' },
              { value: 'part_time', label: 'Part Time' },
              { value: 'commission', label: 'Commission' },
              { value: 'contest', label: 'Contest' },
            ]}
          />
          <Info>
            The description should remain concise and be written in correct
            English. Positions posted on Lemonn are expected to allow remote
            work.
          </Info>
          <AddJobFormField
            name="description"
            label="Description"
            textarea_length={5000}
          />
          <Info>
            This is where the candidates should contact you to send their
            applications. It has to be a valid email address or url (a telegram
            link, a twitter profile, etc...)
          </Info>
          <AddJobFormField name="contact" label="Applicants Contact" />
          <Box textAlign="center">
            <Button disabled={isSubmitting} type="submit" raised>
              Submit
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
export default AddJobForm;

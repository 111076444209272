import React from 'react';
import { FontIcon } from 'components/atoms/Icon';
import styled from 'styled-components';

type StatusType = 'pending' | 'approved' | 'rejected';
interface StatusProps {
  status: StatusType;
}

const StatusContainer = styled.div<StatusProps>`
  display: flex;
  text-transform: capitalize;
  align-items: center;
  color: ${(props) => {
    switch (props.status) {
      case 'pending':
        return props.theme.themeOrange;
      case 'approved':
        return props.theme.themePastelPurple;
      case 'rejected':
        return props.theme.colors.error;
    }
  }};
`;

const Status: React.FunctionComponent<StatusProps> = ({ status }) => {
  const getIcon = () => {
    switch (status) {
      case 'pending':
        return 'schedule';
      case 'approved':
        return 'done';
      case 'rejected':
        return 'close';
    }
  };
  return (
    <StatusContainer status={status}>
      {status}
      <FontIcon marginLeft="4px" icon={getIcon()} />
    </StatusContainer>
  );
};

export default Status;

import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

type NavLinkProps = React.ComponentProps<typeof NavLink>;

export default styled(NavLink)<NavLinkProps>`
  &&& {
    text-decoration: none;
    color: white;
  }
`;

import React from 'react';
import NavLink from 'components/atoms/NavLink';

type MenuProps = {
  path: string;
};

const Menu: React.FunctionComponent<MenuProps> = ({ children, path }) => {
  return (
    <NavLink
      to={path}
      className="mdc-top-app-bar__custom-menu"
      activeClassName="mdc-top-app-bar__custom-menu--active"
    >
      {children}
    </NavLink>
  );
};

export default Menu;

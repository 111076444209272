import styled from 'styled-components';
import {
  MarginProps,
  PaddingProps,
  opacity,
  OpacityProps,
  TypographyProps,
  typography,
  LayoutProps,
  FlexboxProps,
  width,
  height,
  layout,
  flexbox,
  border,
  BorderProps,
  margin,
  color,
  ColorProps,
  padding,
} from 'styled-system';

export default styled.div<
  MarginProps &
    BorderProps &
    ColorProps &
    LayoutProps &
    FlexboxProps &
    TypographyProps &
    OpacityProps &
    PaddingProps
>`
  ${color}
  ${border}
  ${opacity}
  ${typography}
  ${width}
  ${height}
  ${layout}
  ${flexbox}
  ${margin}
  ${padding}
`;

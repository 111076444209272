import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';

interface FileButtonProps extends React.ComponentPropsWithoutRef<'input'> {
  label: string;
  onChange?: (_e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Input = styled.input`
  display: none;
`;

const Label = styled.label`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const FileButton: React.FunctionComponent<FileButtonProps> = ({
  label,
  onChange,
  ...attributes
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<string>(label);
  const handleClick = () => {
    inputRef.current!.click();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0].name);
    } else {
      setFile(label);
    }
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <>
      <Button onClick={handleClick} outlined>
        <Label htmlFor="test">{file ? file : label}</Label>
      </Button>
      <Input
        onChange={handleChange}
        {...attributes}
        ref={inputRef}
        id="test"
        type="file"
      />
    </>
  );
};

export default FileButton;

export const chart_link = (address: string) => {
  return `https://poocoin.app/tokens/${address}`;
};

export const pancake_link = (address: string) => {
  return `https://pancakeswap.finance/swap?outputCurrency=${address}`;
};

export const ape_link = (address: string) => {
  return `https://app.apeswap.finance/swap?outputCurrency=${address}`;
};

export const contract_link = (address: string) => {
  return `https://bscscan.com/token/${address}`;
};

export const twitter_link = (twitter_id: string) => {
  return `https://twitter.com/i/user/${twitter_id}`;
};

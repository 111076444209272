import React from 'react';
import FormError from 'components/atoms/FormError';

import { TokenFormValues } from './AddTokenForm';
import Box from 'components/atoms/Box';
import TextField from 'components/atoms/TextField';
import { useFormikContext, ErrorMessage } from 'formik';
interface AddTokenFormFieldProps {
  name: keyof Omit<TokenFormValues, 'image'>;
  label: string;
  icon?: React.ReactNode;
}

const AddTokenFormField: React.FunctionComponent<AddTokenFormFieldProps> = ({
  name,
  label,
  icon,
}) => {
  const {
    handleChange,
    values,
    touched,
    errors,
    handleBlur,
  } = useFormikContext<TokenFormValues>();
  return (
    <Box mb="16px">
      <TextField
        width="100%"
        label={label}
        leadingIcon={icon}
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]}
        filled
        invalid={touched[name] && !!errors[name]}
      />
      <ErrorMessage name={name}>
        {(msg: string) => <FormError>{msg}</FormError>}
      </ErrorMessage>
    </Box>
  );
};

export default AddTokenFormField;

import React from 'react';
import Box from 'components/atoms/Box';
import Badge from 'components/atoms/Badge';
import styled from 'styled-components';
import { Job } from 'utils/types';
import { NavLink } from 'react-router-dom';

import { displayElapsedTime } from 'utils/format';
export interface JobTitleProps {
  job: Job;
  link?: string;
}

const EllipsisText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Publisher = styled(EllipsisText)`
  color: ${(props) => props.theme.themePastelPurple};
`;
const Title = styled.span`
  font-weight: bold;
  transition: color 0.2s ease;
`;
const CreatedAt = styled.i`
  opacity: 0.6;
  font-size: 0.9em;
  padding-right: 2px;
`;

type NavLinkProps = React.ComponentProps<typeof NavLink>;

const A = styled(NavLink)<NavLinkProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.45em;
  overflow: hidden;
  &:hover {
    .content-card__text--hover-higlight {
      color: ${(props) => props.theme.themeOrange};
    }
  }
`;

const NoA = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.45em;
  overflow: hidden;
`;

const JobTitle: React.FunctionComponent<JobTitleProps> = ({ job, link }) => {
  const content = (
    <>
      <Publisher>{job.publisher.name}</Publisher>
      <EllipsisText>
        <Title className="content-card__text--hover-higlight">
          {job.title}
        </Title>
        {job.created_at && (
          <CreatedAt> - {displayElapsedTime(job.created_at)}</CreatedAt>
        )}
      </EllipsisText>
      <Box display="flex" alignItems="center">
        <Badge label={job.position_type} />
        <Badge label={job.field} />
      </Box>
    </>
  );
  return link ? <A to={link}>{content}</A> : <NoA>{content}</NoA>;
};

export default JobTitle;

import React from 'react';
import styled from 'styled-components';
import Icon from 'components/atoms/Icon';
import { useModal } from 'providers/ModalContext';

const Container = styled.h3`
  display: flex;
  align-items: center;
`;

const HelpIcon = styled(Icon)`
  padding: 8px;
  cursor: pointer;
  color: ${(props) => props.theme.themeOrange};
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

const StyledImg = styled.img`
  margin: 4px 0;
  max-width: 100%;
`;

const ImageFormTitle: React.FunctionComponent = () => {
  const { open } = useModal();
  return (
    <Container>
      Image
      <HelpIcon
        width="1.2em"
        height="1.2em"
        icon="warning"
        onClick={() =>
          open(
            <div>
              <h2>Image Guidelines</h2>
              <p>
                We pay devoted attention to the platform aesthetics, so please
                respect the following guidelines for your image or your
                submission won't be accepted.
              </p>
              <ul style={{ paddingLeft: '20px' }}>
                <li>
                  Logo should not have a transparent background. Use colors!
                  <div>
                    <StyledImg
                      src="https://storage.googleapis.com/public-lemonn/app/examples-images/transparent.png"
                      alt="example-transparent"
                      width="235px"
                      style={{ marginRight: '10px' }}
                    />
                    <StyledImg
                      src="https://storage.googleapis.com/public-lemonn/app/examples-images/transparent-2.png"
                      alt="example-transparent"
                      width="235px"
                    />
                  </div>
                </li>
                <li>
                  Logo should fit in a square
                  <div>
                    <StyledImg
                      src="https://storage.googleapis.com/public-lemonn/app/examples-images/square-long.png"
                      alt="example-square"
                    />
                  </div>
                </li>
                <li>
                  Logo should be centered with reasonable margins
                  <div>
                    <StyledImg
                      src="https://storage.googleapis.com/public-lemonn/app/examples-images/margins.png"
                      alt="example-margins"
                    />
                  </div>
                </li>
              </ul>
              <div className="mdc-dialog__actions">
                <button
                  type="button"
                  className="mdc-button mdc-dialog__button"
                  data-mdc-dialog-action="discard"
                  data-mdc-dialog-initial-focus
                >
                  <div className="mdc-button__ripple"></div>
                  <span className="mdc-button__label">Close</span>
                </button>
              </div>
            </div>
          )
        }
      />
    </Container>
  );
};

export default ImageFormTitle;

import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import JobTitle from 'components/molecules/JobTitle';
import ContentCard, {
  ContentCardLeft,
  ContentCardProps,
  ContentCardRight,
} from 'components/atoms/ContentCard';
import Box from 'components/atoms/Box';
import Links, { Link } from 'components/molecules/Links';
import Button from 'components/atoms/Button';
import JobStatus from 'components/atoms/JobStatus';
import JobManage from 'components/atoms/JobManage';
import AdaptiveSquareImage from 'components/atoms/AdaptiveSquareImage';
import { Job } from 'utils/types';
import {
  chart_link,
  contract_link,
  pancake_link,
  ape_link,
  twitter_link,
} from 'utils/links';

export interface JobInfoProps extends ContentCardProps {
  job: Job;
  wide?: boolean;
  manage?: boolean;
}

const Image = styled(AdaptiveSquareImage)`
  margin-right: 12px;
`;

const ImageHideMobile = styled(Image)`
  @media (max-width: 450px) {
    display: none;
  }
`;

const ImageRight = styled.img`
  margin-right: 0;
  margin-left: 12px;
  height: 60%;
  align-self: flex-start;
  border-radius: 10px 0;
`;

const JobInfo: React.FunctionComponent<JobInfoProps> = ({
  job,
  wide = false,
  manage = false,
  ...attributes
}) => {
  const history = useHistory();
  const links: Link[] = [];
  job.publisher.website &&
    links.push({
      name: 'Website',
      icon: 'world',
      url: job.publisher.website,
    });
  job.publisher.telegram_link &&
    links.push({
      name: 'Telegram',
      icon: 'telegram',
      url: job.publisher.telegram_link,
    });
  job.publisher.twitter_id &&
    links.push({
      name: 'Twitter',
      icon: 'twitter',
      url: twitter_link(job.publisher.twitter_id),
    });
  job.publisher.contract_address &&
    links.push(
      {
        name: 'Contract',
        icon: 'bscscan',
        url: contract_link(job.publisher.contract_address),
      },
      {
        name: 'Chart',
        icon: 'poo',
        url: chart_link(job.publisher.contract_address),
      },
      {
        name: 'ApeSwap',
        icon: 'apeswap',
        url: ape_link(job.publisher.contract_address),
      },
      {
        name: 'PancakeSwap',
        icon: 'pancake',
        url: pancake_link(job.publisher.contract_address),
      }
    );
  return (
    <ContentCard
      height={['80px', '88px']}
      fontSize={['0.9em', '1em']}
      padding={['8px', '8px 12px 8px 8px']}
      {...attributes}
    >
      <ContentCardLeft>
        {wide && <Image src={job.publisher.image} alt={job.publisher.name} />}
        {manage && (
          <ImageHideMobile src={job.publisher.image} alt={job.publisher.name} />
        )}
        <JobTitle job={job} link={`/job/${job.id}`} />
      </ContentCardLeft>
      <ContentCardRight>
        {!(wide || manage) && (
          <ImageRight src={job.publisher.image} alt={job.publisher.name} />
        )}
        {wide && (
          <>
            <Box display={['none', 'initial']}>
              <Links links={links} />
            </Box>
            <Box ml="10px" display={['none', 'initial']}>
              <Button outlined onClick={() => history.push(`/job/${job.id}`)}>
                Apply
              </Button>
            </Box>
          </>
        )}
        {manage && (
          <>
            <Box ml={['8px', '16px']}>
              <JobStatus closed={job.closed} />
            </Box>
            <Box ml={['8px', '16px']}>
              <JobManage job={job} />
            </Box>
          </>
        )}
      </ContentCardRight>
    </ContentCard>
  );
};

export default JobInfo;

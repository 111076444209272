import React from 'react';
import styled from 'styled-components';
import ContentCard, {
  ContentCardLeft,
  ContentCardProps,
  ContentCardRight,
} from 'components/atoms/ContentCard';
import Box from 'components/atoms/Box';
import Badge from 'components/atoms/Badge';
import AdaptiveSquareImage from 'components/atoms/AdaptiveSquareImage';
import JobTitle from 'components/molecules/JobTitle';
import Status from 'components/atoms/Status';
import { JobSubmission } from 'utils/types';
import Icon from 'components/atoms/Icon';
import { useModal } from 'providers/ModalContext';
import { displayElapsedTime } from 'utils/format';

interface JobSubmissionInfoProps extends ContentCardProps {
  jobSubmission: JobSubmission;
}

const SubmittedAt = styled.span`
  opacity: 0.6;
  font-size: 0.9em;
`;

const ReviewMessage = styled.span`
  color: ${(props) => props.theme.colors.error};
`;

const Image = styled(AdaptiveSquareImage)`
  margin-right: 12px;
`;

const InlineBadge = styled(Badge)`
  display: inline-flex;
`;

const StyledIcon = styled(Icon)`
  margin-left: 4px;
  opacity: 0.6;
  width: 24px;
  height: 24px;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

const Description = styled.div`
  white-space: pre-wrap;
  background-color: #ffffff11;
  border-radius: 8px;
  padding: 4px 8px;
  margin: 4px 0;
`;

const JobSubmissionInfo: React.FunctionComponent<JobSubmissionInfoProps> = ({
  jobSubmission,
  ...contentCardProps
}) => {
  const { open } = useModal();
  return (
    <ContentCard
      height={['80px', '88px']}
      padding="8px 12px 8px 8px"
      fontSize={['0.9em', '1em']}
      {...contentCardProps}
    >
      <ContentCardLeft>
        <Box display={['none', 'initial']}>
          <Image
            src={jobSubmission.publisher.image}
            alt={jobSubmission.publisher.name}
          />
        </Box>
        <JobTitle
          job={jobSubmission}
          link={
            jobSubmission.job_id ? `/job/${jobSubmission.job_id}` : undefined
          }
        />
      </ContentCardLeft>
      <ContentCardRight>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          ml="10px"
          lineHeight="1.2em"
        >
          <SubmittedAt>
            <Box display={['none', 'inline']}>Submitted </Box>
            {displayElapsedTime(jobSubmission.submitted_at)}
          </SubmittedAt>
          <Status status={jobSubmission.status} />
        </Box>
        <StyledIcon
          onClick={() => {
            open(
              <>
                <h3>Submission Details</h3>
                <p>
                  <b>Posted with: </b>
                  {jobSubmission.publisher.name}
                  <br />
                  <b>Job Title: </b>
                  {jobSubmission.title}
                  <br />
                  <b>Job Field: </b>
                  <InlineBadge label={jobSubmission.field} />
                  <br />
                  <b>Position Type: </b>
                  <InlineBadge label={jobSubmission.position_type} />
                  <br />
                  <b>Description: </b>
                  <Description>{jobSubmission.description}</Description>
                  <br />
                  {jobSubmission.review_message ? (
                    <ReviewMessage>
                      <b>Review Message :</b> {jobSubmission.review_message}
                    </ReviewMessage>
                  ) : null}
                </p>
                <div className="mdc-dialog__actions">
                  <button
                    type="button"
                    className="mdc-button mdc-dialog__button"
                    data-mdc-dialog-action="discard"
                    data-mdc-dialog-initial-focus
                  >
                    <div className="mdc-button__ripple"></div>
                    <span className="mdc-button__label">Close</span>
                  </button>
                </div>
              </>
            );
          }}
          icon="info"
        />
      </ContentCardRight>
    </ContentCard>
  );
};

export default JobSubmissionInfo;

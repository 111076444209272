import React from 'react';
import Li, { LiProps } from 'components/atoms/Li';
import { Link, useRouteMatch } from 'react-router-dom';

type LiLinkProps = {
  path: string;
  external?: boolean;
};

type InternalLinkProps = {
  to: string;
} & LiProps;
const InternalLiLink: React.FunctionComponent<InternalLinkProps> = ({
  to,
  children,
  ...liAttributes
}) => {
  const match = useRouteMatch({
    path: to,
    exact: true,
  });
  return (
    <Link to={to} rel="noreferrer">
      <Li activated={!!match} {...liAttributes}>
        {children}{' '}
      </Li>
    </Link>
  );
};

const LiLink: React.FunctionComponent<LiLinkProps & LiProps> = ({
  path,
  external,
  children,
  ...liAttributes
}) => {
  if (external) {
    return (
      <a href={path} rel="noreferrer" target="_blank">
        <Li {...liAttributes}>{children} </Li>
      </a>
    );
  } else
    return (
      <InternalLiLink to={path} {...liAttributes}>
        {children}
      </InternalLiLink>
    );
};

export default LiLink;

import React from 'react';
import Icon, { IconType } from 'components/atoms/Icon';
import styled from 'styled-components';

type LiGraphicProps = {
  icon: IconType;
};

const IconContainerBase: React.FunctionComponent<{ className?: string }> = ({
  className,
  children,
}) => <div className={`mdc-list-item__graphic ${className}`}>{children}</div>;

const IconContainer = styled(IconContainerBase)`
  font-size: 24px;
  color: white;
`;

const LiGraphic: React.FunctionComponent<LiGraphicProps> = ({ icon }) => {
  return (
    <IconContainer>
      <Icon width="1em" height="1em" icon={icon} />
    </IconContainer>
  );
};

export default LiGraphic;

import React from 'react';
import Box from 'components/atoms/Box';
import styled from 'styled-components';
import { variant } from 'styled-system';
import Icon from 'components/atoms/Icon';
import { useModal } from 'providers/ModalContext';

export interface AwardCountProps {
  count: number;
  is_first: boolean;
}

const variants = (theme: any) => {
  return {
    level1: {
      fill: '#b9b9b8',
      color: '#b9b9b8',
      backgroundColor: '#ffffff15',
    },
    level2: {
      fill: theme.themeOrange,
      color: theme.themeOrange,
      backgroundColor: '#ffffff15',
    },
    level3: {
      fill: theme.themeDarkPurple,
      color: theme.themeDarkPurple,
      background: 'linear-gradient(45deg, #ff8d00, #ffee4f)',
    },
  };
};

type Variant = 'level1' | 'level2' | 'level3';
const Container = styled.div<{ variant: Variant }>`
  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 100px;
  cursor: pointer;
  ${(props) =>
    variant({
      variants: variants(props.theme),
    })};
`;

const getVariant = (is_first: boolean, count: number) => {
  if (is_first) return 'level3';
  if (count >= 10) return 'level2';
  return 'level1';
};
const AwardCount: React.FunctionComponent<AwardCountProps> = ({
  count,
  is_first,
}) => {
  const { open } = useModal();
  return (
    <Container
      variant={getVariant(is_first, count)}
      onClick={() =>
        open(
          <div>
            <p>
              Awards are rare and qualitative because they can only be given by
              logged-in Lemonn holders, and only once per account.
            </p>
            <ul>
              <li>Awarded tokens show a special silver badge.</li>
              <li>
                Tokens with more than 10 awards are granted a golden badge.
              </li>
              <li>
                The most awarded token is displayed on the top of the page and
                gets the highlighted blazing badge.
              </li>
              <li>Awards, like upvotes, are reset each Monday at 7pm UTC</li>
            </ul>
            <div className="mdc-dialog__actions">
              <button
                type="button"
                className="mdc-button mdc-dialog__button"
                data-mdc-dialog-action="discard"
                data-mdc-dialog-initial-focus
              >
                <div className="mdc-button__ripple"></div>
                <span className="mdc-button__label">Close</span>
              </button>
            </div>
          </div>
        )
      }
    >
      <Icon width="16px" height="16px" icon="to_the_moon" />
      <Box marginLeft="8px">{count}</Box>
    </Container>
  );
};

export default AwardCount;

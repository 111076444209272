import React from 'react';
import styled from 'styled-components';

import { useQueryClient, useMutation } from 'react-query';
import axios from 'axios';

import { useLogin } from 'providers/LoginContext';
import { useQueryKey } from 'utils/hooks';

import Button from 'components/atoms/Button';
import Box from 'components/atoms/Box';
import Icon from 'components/atoms/Icon';
import { InfiniteTokensData, Token } from 'utils/types';

interface IconProps {
  upvoted: boolean;
  upvotes: number;
  token_id: number;
}

const StyledButton = styled(Button)`
  padding: 0 6px;
  ${(props) => `${props.theme.mediaQueries.tablet} {
    padding: 0 15px 0 15px;
  }`};
`;

function amountFormatter(amount: number) {
  if (amount < 1000) {
    return amount.toFixed(0);
  } else if (amount < 10000) {
    return `${(amount / 1000).toFixed(1)}k`;
  } else {
    return `${(amount / 1000).toFixed(0)}k`;
  }
}

const Upvote: React.FunctionComponent<IconProps> = ({
  upvotes,
  upvoted,
  token_id,
}) => {
  const { user } = useLogin();
  const client = useQueryClient();
  const mostAwardedQueryKey = useQueryKey('/token-race/token/most-awarded/');

  const upvoteMutation = useMutation(
    async (remove: boolean) => {
      let headers = {};
      if (user) {
        const idToken = await user.getIdToken();
        headers = {
          Authorization: `Bearer ${idToken}`,
        };
      }
      const route_suffix = remove ? 'remove/' : '';
      return await axios.post(
        `${process.env.REACT_APP_LEMON_API}/token-race/upvote/${route_suffix}`,
        {
          token_id: token_id,
        },
        {
          headers: headers,
        }
      );
    },
    {
      onSuccess: (response, remove) => {
        const diff = remove ? -1 : 1;
        var mostAwarded = client.getQueryData<Token>(mostAwardedQueryKey);
        if (mostAwarded) {
          client.setQueryData(
            mostAwardedQueryKey,
            mostAwarded.id === token_id
              ? {
                  ...mostAwarded,
                  upvotes: mostAwarded.upvotes + diff,
                  upvoted: !remove,
                }
              : mostAwarded
          );
        }
        client.setQueriesData(
          { queryKey: '/token-race/token/', active: true },
          (tokenData: InfiniteTokensData | undefined) => {
            if (tokenData) {
              const newPagesArray = tokenData.pages.map((page) => {
                return {
                  ...page,
                  tokens: page.tokens.map((token) =>
                    token.id === token_id
                      ? {
                          ...token,
                          upvotes: token.upvotes + diff,
                          upvoted: !remove,
                        }
                      : token
                  ),
                };
              });
              return {
                pages: newPagesArray,
                pageParams: tokenData.pageParams,
              };
            } else {
              return {
                pages: [],
                pageParams: [],
              };
            }
          }
        );
      },
    }
  );

  const handleClick = () => {
    upvoteMutation.mutate(upvoted);
  };

  return (
    <StyledButton
      width={['55px', '75px']}
      minWidth={['58px', '75px']}
      height={['30px', '36px']}
      onClick={handleClick}
      state={upvoted ? 'activated' : undefined}
      outlined
    >
      <Box fontSize={['0.8em', '1em']} display="flex" alignItems="center">
        {amountFormatter(upvotes)}
        <Icon
          marginLeft="5px"
          width={['9px', '1em']}
          height={['9px', '1em']}
          icon="upvote"
        />
      </Box>
    </StyledButton>
  );
};

export default Upvote;

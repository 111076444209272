import React, { useState } from 'react';
import LoginButton from 'components/atoms/LoginButton';
import TopAppBar from 'components/molecules/TopAppBar';
import MobileDrawer from 'components/organisms/MobileDrawer';
import Icon from 'components/atoms/Icon';
import {
  Title,
  Menu as MenuLink,
  Section,
  Row,
  ActionItem,
} from 'components/molecules/TopAppBar';
import LoggedInMenu from './LoggedInMenu';
import { ReactComponent as MenuIcon } from 'components/icons/menu.svg';

import { useLogin } from 'providers/LoginContext';

const AppBar: React.FunctionComponent = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const openDrawer = () => {
    setIsDrawerOpen(true);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };
  const { user } = useLogin();
  return (
    <>
      <TopAppBar fixed>
        {user !== undefined && (
          <Row>
            <Section alignStart>
              <Title />
            </Section>
            <Section alignEnd>
              <MenuLink path="/hub">
                <Icon width="24px" height="24px" icon="home" />
                <span> Token Hub </span>
              </MenuLink>
              <MenuLink path="/add-token">
                <Icon width="24px" height="24px" icon="add_circle" />
                <span> Add a Token</span>
              </MenuLink>
              <MenuLink path="/jobs">
                <Icon width="24px" height="24px" icon="work" />
                <span> Jobs </span>
              </MenuLink>
              <MenuLink path="/about">
                <Icon width="24px" height="24px" icon="help_outline" />
                <span> About </span>
              </MenuLink>
              {!user && <LoginButton />}
              {user && <LoggedInMenu display={['none', 'initial']} />}
              <ActionItem onClick={openDrawer} drawerToggle>
                <MenuIcon />
              </ActionItem>
            </Section>
          </Row>
        )}
      </TopAppBar>
      <MobileDrawer isOpen={isDrawerOpen} close={closeDrawer} />
    </>
  );
};

export default AppBar;

import React from 'react';

import { Token } from 'utils/types';
import {
  chart_link,
  contract_link,
  pancake_link,
  ape_link,
  twitter_link,
} from 'utils/links';

import ContentCard, {
  ContentCardLeft,
  ContentCardProps,
  ContentCardRight,
} from 'components/atoms/ContentCard';
import TokenTitle from 'components/molecules/TokenTitle';
import Links from 'components/molecules/Links';
import Box from 'components/atoms/Box';
import Upvote from 'components/atoms/Upvote';
import Award from 'components/atoms/Award';
import AwardCount from 'components/atoms/AwardCount';

interface TokenProps extends ContentCardProps {
  token: Token;
  is_most_awarded: boolean;
}

const TokenInfo: React.FunctionComponent<TokenProps> = ({
  token,
  is_most_awarded,
  ...contentCardProps
}) => {
  return (
    <ContentCard padding="8px 12px 8px 8px" {...contentCardProps}>
      <ContentCardLeft>
        <TokenTitle
          imgUrl={token.image}
          name={token.name}
          symbol={token.symbol}
          link={`/token/${token.id}`}
        />
        {token.awards > 0 && (
          <Box display="flex" alignItems="center" marginLeft="12px">
            <AwardCount count={token.awards} is_first={is_most_awarded} />
          </Box>
        )}
      </ContentCardLeft>
      <ContentCardRight>
        <Box display={['none', 'initial']}>
          <Links
            links={[
              {
                name: 'Website',
                icon: 'world',
                url: token.website,
              },
              {
                name: 'Telegram',
                icon: 'telegram',
                url: token.telegram_link,
              },
              {
                name: 'Twitter',
                icon: 'twitter',
                url: twitter_link(token.twitter_id),
              },
              {
                name: 'Contract',
                icon: 'bscscan',
                url: contract_link(token.contract_address),
              },
              {
                name: 'Chart',
                icon: 'poo',
                url: chart_link(token.contract_address),
              },
              {
                name: 'ApeSwap',
                icon: 'apeswap',
                url: ape_link(token.contract_address),
              },
              {
                name: 'PancakeSwap',
                icon: 'pancake',
                url: pancake_link(token.contract_address),
              },
            ]}
          />
        </Box>
        <Box ml="10px">
          <Upvote
            upvoted={token.upvoted}
            upvotes={token.upvotes}
            token_id={token.id}
          />
        </Box>
        <Box ml="10px">
          <Award token_id={token.id} awarded={token.awarded} />
        </Box>
      </ContentCardRight>
    </ContentCard>
  );
};

export default TokenInfo;

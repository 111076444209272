import React from 'react';
import { ReactComponent as Lemonn } from 'components/icons/lemonn.svg';
import NavLink from 'components/atoms/NavLink';
import styled from 'styled-components';

const StyledLemonn = styled(Lemonn)`
  height: 32px;
  width: 32px;
  @media (min-width: 600px) {
    height: 38px;
    width: 38px;
  }
`;

const Title: React.FunctionComponent = () => {
  return (
    <NavLink className="mdc-top-app-bar__custom-title-container" to="/">
      <StyledLemonn />
      <span
        className="mdc-top-app-bar__title mdc-top-app-bar__title--custom-title"
        style={{ fontWeight: 'bolder' }}
      >
        Lemonn
      </span>
    </NavLink>
  );
};
export default Title;

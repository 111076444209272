import React from 'react';
import styled from 'styled-components';
import Icon, { IconType } from 'components/atoms/Icon';

const Styled = styled.span`
  display: flex;
  align-items: center;
  background-color: #ffffff11;
  color: #ffffff99;
  border-radius: 4px;
  font-size: 0.7em;
  padding: 0 4px;
  height: 22px;
  margin: 2px 4px 0 0;
  white-space: nowrap;
`;

export interface BadgeProps extends React.ComponentPropsWithoutRef<'span'> {
  label: string;
}

const icon_label_mapping: { [name: string]: { icon: IconType; text: string } } =
  {
    growth: { icon: 'trending', text: 'Growth' },
    technology: { icon: 'code', text: 'Technology' },
    art: { icon: 'palette', text: 'Art & Design' },
    community: { icon: 'people', text: 'Community Relations' },
    content: { icon: 'notes', text: 'Content' },
  };

const label_mapping: { [name: string]: string } = {
  full_time: 'Full Time',
  part_time: 'Part Time',
  commission: 'Commission',
  contest: 'Contest',
  other: 'Other',
};

const Badge: React.FunctionComponent<BadgeProps> = ({
  label,
  ...attributes
}) => {
  if (label in icon_label_mapping) {
    return (
      <Styled {...attributes}>
        <Icon
          width="12px"
          height="12px"
          icon={icon_label_mapping[label]['icon']}
          style={{ marginRight: '4px' }}
        />{' '}
        {icon_label_mapping[label]['text']}
      </Styled>
    );
  } else if (label in label_mapping) {
    return <Styled {...attributes}>{label_mapping[label]}</Styled>;
  } else {
    return <Styled {...attributes}>{label}</Styled>;
  }
};

export default Badge;

import React from 'react';
import Icon from 'components/atoms/Icon';
import styled from 'styled-components';
import TextField from 'components/atoms/TextField';

interface SearchFieldProps {
  value: string;
  onChange: (_e: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledTextField = styled(TextField)`
  margin-left: 8px;
`;

const SearchField: React.FunctionComponent<SearchFieldProps> = ({
  value,
  onChange,
}) => {
  return (
    <StyledTextField
      value={value}
      height="40px"
      onChange={onChange}
      className="mdc-text-field--outlined-discrete"
      label="Search Tokens"
      trailingIcon={<Icon width="24px" height="24px" icon="search" />}
      outlined
    />
  );
};

export default SearchField;

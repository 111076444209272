import React from 'react';
import { Publisher, Job } from 'utils/types';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Page from 'components/templates/Page';
import Grid, { Inner, Cell } from 'components/templates/Grid';
import Section, { Header } from 'components/templates/Section';
import styled from 'styled-components';
import Box from 'components/atoms/Box';
import { Link, ExpandedLinks } from 'components/molecules/Links';
import Ul from 'components/atoms/Ul';
import JobInfo from 'components/organisms/JobInfo';
import ViewAllJobs from 'components/atoms/ViewAllJobs';

import { twitter_link } from 'utils/links';

type RecruiterPageRouteParams = {
  recruiter_id: string;
};

const Img = styled.img`
  border-radius: 10px 0;
  object-fit: cover;
  width: 60px;
  height: 60px;
  margin-right: 16px;
`;

const RecruiterTitle = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  line-height: 1.9em;
`;

const EllipsisText = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Name = styled(EllipsisText)`
  font-size: 1.6em;
  font-weight: bolder;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  overflow: hidden;
`;

const getLinks = (publisher: Publisher) => {
  const links: Link[] = [];
  publisher.website &&
    links.push({
      name: 'Website',
      icon: 'world',
      url: publisher.website,
    });
  publisher.telegram_link &&
    links.push({
      name: 'Telegram',
      icon: 'telegram',
      url: publisher.telegram_link,
    });
  publisher.twitter_id &&
    links.push({
      name: 'Twitter',
      icon: 'twitter',
      url: twitter_link(publisher.twitter_id),
    });
  return links;
};

const RecruiterPage: React.FunctionComponent = () => {
  const params = useParams<RecruiterPageRouteParams>();
  const recruiterQuery = useQuery<Publisher>(
    `/jobs/recruiter/${params.recruiter_id}/`
  );
  const jobsQuery = useQuery<Job[]>(
    `/jobs/recruiter/${params.recruiter_id}/jobs/`
  );

  return (
    <Page
      title={
        recruiterQuery.isSuccess
          ? `Lemonn | ${recruiterQuery.data.name}`
          : 'Lemonn'
      }
      error={recruiterQuery.isError}
      loading={recruiterQuery.isLoading}
    >
      <Grid>
        <Inner>
          <Cell solo_column>
            {recruiterQuery.isSuccess && (
              <>
                <Box mt={['12px', '20px']} mb={['12px', '16px']}>
                  <RecruiterTitle>
                    <Img
                      src={recruiterQuery.data.image}
                      alt={recruiterQuery.data.name}
                    />
                    <TextContainer>
                      <Name>{recruiterQuery.data.name}</Name>
                    </TextContainer>
                  </RecruiterTitle>
                </Box>
                <Box mb={['8px', '12px']}>
                  <ExpandedLinks links={getLinks(recruiterQuery.data)} />
                </Box>
                <Section>
                  <Header
                    icon="work"
                    title="Open positions from this recruiter"
                  />
                  {jobsQuery.isSuccess && (
                    <Ul style={{ padding: '0', marginBottom: '20px' }}>
                      {jobsQuery.data.map((job) => (
                        <li key={job.id} style={{ marginBottom: '10px' }}>
                          <JobInfo job={job} />
                        </li>
                      ))}
                      {jobsQuery.data.length === 0 && (
                        <Box textAlign="center">
                          <p>
                            There is currently no open jobs from this recruiter.
                          </p>
                        </Box>
                      )}
                    </Ul>
                  )}
                  <ViewAllJobs />
                </Section>
              </>
            )}
          </Cell>
        </Inner>
      </Grid>
    </Page>
  );
};
export default RecruiterPage;

import React from 'react';
import styled from 'styled-components';
import Page from 'components/templates/Page';
import AddProfileForm from 'components/organisms/AddProfileForm';
import Grid, { Inner, Cell } from 'components/templates/Grid';
import { useLogin } from 'providers/LoginContext';
import LoginButton from 'components/atoms/LoginButton';

const H1 = styled.h1`
  text-align: center;
`;

const H2 = styled.h2`
  text-align: center;
`;

const Info = styled.p`
  font-style: italic;
  opacity: 0.8;
  font-size: 0.9em;
`;
const AddToken: React.FunctionComponent = () => {
  const { user } = useLogin();

  return (
    <Page title="Lemonn | Create a Recruiter Profile">
      <Grid>
        <Inner>
          <Cell solo_column>
            <H1> Create a Custom Recruiter Profile </H1>
            {user && (
              <>
                <Info>
                  {' '}
                  Only Name and Image are required, but we highly recommend you
                  to fill at least one of the links input field to pass the
                  review process.
                </Info>
                <AddProfileForm />
              </>
            )}
            {!user && (
              <>
                <H2>Please Log In to be able to manage recruiter profiles.</H2>
                <H2>
                  <LoginButton />
                </H2>
              </>
            )}
          </Cell>
        </Inner>
      </Grid>
    </Page>
  );
};
export default AddToken;

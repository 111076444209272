import React from 'react';
import styled from 'styled-components';
import Page from 'components/templates/Page';
import AddTokenForm from 'components/organisms/AddTokenForm';
import Grid, { Inner, Cell } from 'components/templates/Grid';
import { useLogin } from 'providers/LoginContext';
import LoginButton from 'components/atoms/LoginButton';

const H1 = styled.h1`
  text-align: center;
`;

const H2 = styled.h2`
  text-align: center;
`;

const Info = styled.p`
  font-style: italic;
  opacity: 0.8;
  font-size: 0.9em;
`;
const AddToken: React.FunctionComponent = () => {
  const { user } = useLogin();

  return (
    <Page title="Lemonn | Add a Token">
      <Grid>
        <Inner>
          <Cell solo_column>
            <H1> Add a Token </H1>
            {user && (
              <>
                <Info> All the fields are required </Info>
                <AddTokenForm />
              </>
            )}
            {!user && (
              <>
                <H2>Please Log In to be able to add tokens.</H2>
                <H2>
                  <LoginButton />
                </H2>
              </>
            )}
          </Cell>
        </Inner>
      </Grid>
    </Page>
  );
};
export default AddToken;

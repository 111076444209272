import React from 'react';
import Button from 'components/atoms/Button';
import styled from 'styled-components';
import axios from 'axios';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useLogin } from 'providers/LoginContext';
import Icon from 'components/atoms/Icon';

import { InfiniteTokensData, Token } from 'utils/types';
import { AccountInfo } from 'utils/types';
import { useModal } from 'providers/ModalContext';
import { useQueryKey } from 'utils/hooks';

interface AwardProps {
  awarded?: boolean;
  token_id: number;
}

const StyledButton = styled(Button)`
  padding: 0 7px 0 3px;
  min-width: initial;
`;

const Award: React.FunctionComponent<AwardProps> = ({ awarded, token_id }) => {
  const { open } = useModal();
  const { user } = useLogin();
  const accountInfoQuery = useQuery<AccountInfo>(
    'accountInfo',
    async () => {
      const response = await axios.get(
        `https://europe-west1-cryptos-tools.cloudfunctions.net/get-account-data?uid=${
          user!.uid
        }`
      );
      return response.data;
    },
    { enabled: !!user }
  );
  const client = useQueryClient();
  const mostAwardedQueryKey = useQueryKey('/token-race/token/most-awarded/');

  const removeAwardMutation = useMutation(
    async () => {
      if (!user) {
        throw new Error('Missing user');
      }
      const idToken = await user.getIdToken();
      return await axios.post(
        `${process.env.REACT_APP_LEMON_API}/token-race/award/remove/`,
        {},
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        var mostAwarded = client.getQueryData<Token>(mostAwardedQueryKey);
        if (mostAwarded) {
          client.setQueryData(mostAwardedQueryKey, {
            ...mostAwarded,
            awards: mostAwarded.awarded
              ? mostAwarded.awards - 1
              : mostAwarded.awards,
            awarded: false,
          });
        }
        client.setQueriesData(
          { queryKey: '/token-race/token/', active: true },
          (tokenData: InfiniteTokensData | undefined) => {
            if (tokenData) {
              const newPagesArray = tokenData.pages.map((page) => {
                return {
                  ...page,
                  tokens: page.tokens.map((token) => {
                    return {
                      ...token,
                      awards: token.awarded ? token.awards - 1 : token.awards,
                      awarded: false,
                    };
                  }),
                };
              });
              return {
                pages: newPagesArray,
                pageParams: tokenData.pageParams,
              };
            } else {
              return {
                pages: [],
                pageParams: [],
              };
            }
          }
        );
      },
    }
  );

  const awardMutation = useMutation(
    async (force: boolean) => {
      if (!user) {
        throw new Error('Missing user');
      }
      const idToken = await user.getIdToken();
      const route_suffix = force ? 'replace/' : '';
      return await axios.post(
        `${process.env.REACT_APP_LEMON_API}/token-race/award/${route_suffix}`,
        {
          token_id: token_id,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        var mostAwarded = client.getQueryData<Token>(mostAwardedQueryKey);
        const activeTokenData = client.getQueriesData<InfiniteTokensData>({
          queryKey: '/token-race/token/',
          active: true,
        })[0][1];
        // Update the most awarded if it is the awarded, but if it is not,
        // also check in the active token data if the awarded is now first
        if (mostAwarded && activeTokenData) {
          const awardedToken = activeTokenData.pages
            .map((page) => page.tokens)
            .flat()
            .find((token) => token.id === token_id);
          client.setQueryData(
            mostAwardedQueryKey,
            mostAwarded.id === token_id
              ? {
                  ...mostAwarded,
                  awards: mostAwarded.awards + 1,
                  awarded: true,
                }
              : awardedToken &&
                (awardedToken.awards >= mostAwarded.awards ||
                  (awardedToken.awards + 1 >= mostAwarded.awards &&
                    mostAwarded.awarded))
              ? {
                  ...awardedToken,
                  awards: awardedToken.awards + 1,
                  awarded: true,
                }
              : {
                  ...mostAwarded,
                  awards: mostAwarded.awarded
                    ? mostAwarded.awards - 1
                    : mostAwarded.awards,
                  awarded: false,
                }
          );
        }
        client.setQueriesData(
          { queryKey: '/token-race/token/', active: true },
          (tokenData: InfiniteTokensData | undefined) => {
            if (tokenData) {
              const newPagesArray = tokenData.pages.map((page) => {
                return {
                  ...page,
                  tokens: page.tokens.map((token) =>
                    token.id === token_id
                      ? {
                          ...token,
                          awards: token.awards + 1,
                          awarded: true,
                        }
                      : {
                          ...token,
                          awards: token.awarded
                            ? token.awards - 1
                            : token.awards,
                          awarded: false,
                        }
                  ),
                };
              });
              return {
                pages: newPagesArray,
                pageParams: tokenData.pageParams,
              };
            } else {
              return {
                pages: [],
                pageParams: [],
              };
            }
          }
        );
      },
      onError: () => {
        // Can't manage to get the error response here to double check.
        // I probably don't understand something... to discuss with Ellie
        open(
          <div>
            You can only award one token each week, and you have already awarded
            a token this week. Do you want to change your award ?
            <div className="mdc-dialog__actions">
              <span dir="rtl">
                <button
                  type="button"
                  className="mdc-button mdc-dialog__button"
                  data-mdc-dialog-action="discard"
                  data-mdc-dialog-initial-focus
                >
                  <div className="mdc-button__ripple"></div>
                  <span className="mdc-button__label">Cancel</span>
                </button>
                <button
                  type="button"
                  className="mdc-button mdc-dialog__button"
                  data-mdc-dialog-action="accept"
                  onClick={handleConfirmationClick}
                >
                  <div className="mdc-button__ripple"></div>
                  <span className="mdc-button__label">Change Award</span>
                </button>
              </span>
            </div>
          </div>
        );
      },
    }
  );

  const handleClick = () => {
    if (user === null) {
      open(
        <div>
          To award a token, you have to be logged-in and hold at least 100
          Lemonn Tokens.
          <div className="mdc-dialog__actions">
            <button
              type="button"
              className="mdc-button mdc-dialog__button"
              data-mdc-dialog-action="discard"
              data-mdc-dialog-initial-focus
            >
              <div className="mdc-button__ripple"></div>
              <span className="mdc-button__label">Close</span>
            </button>
          </div>
        </div>
      );
      return;
    }
    if (!accountInfoQuery.isSuccess) return;
    if (!accountInfoQuery.data.is_lemonn_premium) {
      open(
        <div>
          You have to hold at least 100 Lemonn Tokens to be able to award.
          Please use the{' '}
          <a
            href="https://accounts.moonlighttoken.com/"
            target="_blank"
            rel="noreferrer"
          >
            Moonlight account platform
          </a>{' '}
          to learn more about this and get access.
          <br />
          <br />
          If you changed your account on the account platform, please log out
          and log in again here to ensure you're using the correct account.
          <div className="mdc-dialog__actions">
            <button
              type="button"
              className="mdc-button mdc-dialog__button"
              data-mdc-dialog-action="discard"
              data-mdc-dialog-initial-focus
            >
              <div className="mdc-button__ripple"></div>
              <span className="mdc-button__label">Close</span>
            </button>
          </div>
        </div>
      );
      return;
    }
    awardMutation.mutate(false);
  };

  const handleConfirmationClick = () => {
    awardMutation.mutate(true);
  };

  const handleRemoveClick = () => {
    removeAwardMutation.mutate();
  };

  return (
    <StyledButton
      outlined
      state={awarded ? 'fancy' : undefined}
      height={['30px', '36px']}
      onClick={awarded ? handleRemoveClick : handleClick}
    >
      {awarded && (
        <Icon height={['14px', '16px']} width={['14px', '16px']} icon="done" />
      )}
      {!awarded && (
        <Icon height={['14px', '16px']} width={['14px', '16px']} icon="add" />
      )}
      <Icon
        height={['14px', '16px']}
        width={['14px', '16px']}
        icon="to_the_moon"
      />
    </StyledButton>
  );
};

export default Award;

import dayjs from 'dayjs';

export const displayTimeDiff = (date1: string | dayjs.Dayjs, date2: string) => {
  const minutes = dayjs(date1).diff(date2, 'minute');
  const hours = dayjs(date1).diff(date2, 'hour');
  const days = dayjs(date1).diff(date2, 'day');
  const weeks = dayjs(date1).diff(date2, 'week');
  const months = dayjs(date1).diff(date2, 'month');
  const years = dayjs(date1).diff(date2, 'years');
  if (minutes === 0) {
    return 'just now';
  } else if (minutes < 60) {
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  } else if (hours < 24) {
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  } else if (days < 7) {
    return `${days} day${days === 1 ? '' : 's'} ago`;
  } else if (weeks < 5) {
    return `${weeks} week${weeks === 1 ? '' : 's'} ago`;
  } else if (months < 12) {
    return `${months} month${months === 1 ? '' : 's'} ago`;
  } else {
    return `${years} year${years === 1 ? '' : 's'} ago`;
  }
};

export const displayElapsedTime = (date: string) => {
  return displayTimeDiff(dayjs(), date);
};

import React from 'react';
import dayjs from 'dayjs';
import styled from 'styled-components';
import JobInfo from 'components/organisms/JobInfo';
import { Job, QueryError } from 'utils/types';
import Page from 'components/templates/Page';
import Ul from 'components/atoms/Ul';
import { useQuery } from 'react-query';
import Grid, { Inner, Cell } from 'components/templates/Grid';
import { useLogin } from 'providers/LoginContext';
import LoginButton from 'components/atoms/LoginButton';
import { useQueryKey } from 'utils/hooks';
import { useHistory, NavLink } from 'react-router-dom';
import Button from 'components/atoms/Button';
import Box from 'components/atoms/Box';

const H1 = styled.h1`
  text-align: center;
`;
const H2 = styled.h2`
  text-align: center;
`;
const P = styled.p`
  text-align: center;
  line-height: 1.5em;
`;

type NavLinkProps = React.ComponentProps<typeof NavLink>;
const StyledNavLink = styled(NavLink)<NavLinkProps>`
  &&& {
    color: ${(props) => props.theme.themePastelPurple};
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Submissions = () => {
  const { user } = useLogin();
  const queryKey = useQueryKey('/jobs/job/me/');
  const myJobsQuery = useQuery<Job[], QueryError>(queryKey, {
    retry: (failureCount, error) =>
      error.response?.status !== 401 && failureCount < 3,
  });
  const history = useHistory();

  return (
    <Page
      title="Lemonn | Job Offers"
      error={myJobsQuery.isError && myJobsQuery.error.response?.status !== 401}
      loading={myJobsQuery.isLoading}
    >
      <Grid>
        <Inner>
          <Cell solo_column>
            <H1> Your Job Offers </H1>
            {user && (
              <Ul style={{ padding: '0' }}>
                {myJobsQuery.isSuccess &&
                  (myJobsQuery.data.length > 0 ? (
                    myJobsQuery.data
                      .sort((firstEl, secondEl) => {
                        return (
                          dayjs(secondEl.created_at).valueOf() -
                          dayjs(firstEl.created_at).valueOf()
                        );
                      })
                      .map((job) => (
                        <li key={job.id} style={{ marginBottom: '10px' }}>
                          <JobInfo job={job} manage />
                        </li>
                      ))
                  ) : (
                    <>
                      <H2>There is nothing to show here yet.</H2>
                      <P>
                        <Box mt="32px" mb="24px">
                          <Button
                            raised
                            onClick={() => history.push('/post-job')}
                          >
                            Post a Job
                          </Button>
                        </Box>
                        If you've already submitted a job offer, it has not been
                        approved yet.
                        <br /> Check out its status in{' '}
                        <StyledNavLink to="/account/submissions">
                          your submissions page.
                        </StyledNavLink>
                      </P>
                    </>
                  ))}
              </Ul>
            )}
            {!user && (
              <>
                <H2>Please Log In to access your job offers.</H2>
                <H2>
                  <LoginButton />
                </H2>
              </>
            )}
          </Cell>
        </Inner>
      </Grid>
    </Page>
  );
};

export default Submissions;

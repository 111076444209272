import React from 'react';

interface CellProps {
  span?: number;
  desktop_span?: number;
  tablet_span?: number;
  solo_column?: boolean;
  // Accept the className passed by styled react components, and embed it
  // directly in the className attributes to make them coexists with styled ones
  className?: string;
}

const Cell: React.FunctionComponent<CellProps> = ({
  children,
  span,
  desktop_span,
  tablet_span,
  solo_column,
  className,
}) => {
  if (solo_column) {
    return (
      <div
        className={`${className} mdc-layout-grid__cell mdc-layout-grid__cell--span-12`}
        style={{ maxWidth: '750px', margin: 'auto', width: '100%' }}
      >
        {children}
      </div>
    );
  } else {
    const spanClass = span ? `mdc-layout-grid__cell--span-${span}` : '';
    const desktopSpanClass = desktop_span
      ? `mdc-layout-grid__cell--span-${desktop_span}-desktop`
      : '';
    const tabletSpanClass = tablet_span
      ? `mdc-layout-grid__cell--span-${tablet_span}-tablet`
      : '';
    return (
      <div
        className={`${className} mdc-layout-grid__cell ${spanClass} ${desktopSpanClass} ${tabletSpanClass}`}
      >
        {children}
      </div>
    );
  }
};

export default Cell;

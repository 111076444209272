import React from 'react';
import Icon from 'components/atoms/Icon';
import styled from 'styled-components';

import { Link } from './Links';

type ExpandedLinksProps = {
  links: Link[];
};

const LinkButton = styled.a`
  background-color: ${(props) => props.theme.themeBlendingSurface};
  padding: 8px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  margin: 0 6px 6px 0;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: ${(props) => props.theme.themePurple};
  }
`;

const Label = styled.span`
  margin-left: 8px;
`;

const ExpandedLinks: React.FunctionComponent<ExpandedLinksProps> = ({
  links,
}) => {
  return (
    <div>
      {links.map((link) => {
        return (
          <LinkButton href={link.url} rel="noreferrer" target="_blank">
            <Icon width="16px" height="16px" icon={link.icon} />
            <Label>{link.name}</Label>
          </LinkButton>
        );
      })}
    </div>
  );
};

export default ExpandedLinks;

import React from 'react';
import styled from 'styled-components';
import { variant } from 'styled-system';

export interface JobStatusProps {
  closed: boolean;
}

const variants = (theme: any) => {
  return {
    closed: {
      backgroundColor: theme.colors.error,
    },
    open: {
      backgroundColor: theme.colors.green,
    },
  };
};

type Variant = 'closed' | 'open';
const Container = styled.div<{ variant: Variant }>`
  display: flex;
  align-items: center;
  border-radius: 100px;
  font-size: 0.7em;
  padding: 0 8px;
  height: 22px;
  ${(props) =>
    variant({
      variants: variants(props.theme),
    })};
`;

const getVariant = (closed: boolean) => {
  return closed ? 'closed' : 'open';
};

const JobStatus: React.FunctionComponent<JobStatusProps> = ({ closed }) => {
  return (
    <Container variant={getVariant(closed)}>
      {closed ? 'Closed' : 'Open'}
    </Container>
  );
};

export default JobStatus;

import React from 'react';

import Icon, { IconProps } from './Icon';

type FontIconProps = Omit<IconProps, 'height' | 'width'>;

const FontIcon: React.FunctionComponent<FontIconProps> = (props) => {
  return <Icon {...props} width="1em" height="1em" />;
};

export default FontIcon;

import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { width, WidthProps } from 'styled-system';
import { MDCSelect } from '@material/select';
import Menu from 'components/atoms/Menu';

export type SelectOption = {
  label: string;
  value: string;
};

export type SelectProps = {
  name: string;
  label: string;
  options: SelectOption[];
  invalid?: boolean;
  withEmpty?: boolean;
  discrete?: boolean;
  customOnChange: (arg0: string) => void;
} & WidthProps;

const StyledDiv = styled.div`
  ${width}
`;

const Select: React.FunctionComponent<SelectProps> = ({
  label,
  options,
  invalid,
  customOnChange,
  withEmpty,
  discrete,
  ...stylingProps
}) => {
  const selectRef = useRef<HTMLDivElement>(null);
  const selectManager = useRef<MDCSelect | null>(null);

  useEffect(() => {
    selectManager.current = new MDCSelect(selectRef.current!);
    selectManager.current.listen('MDCSelect:change', () => {
      customOnChange(selectManager.current!.value);
    });
    return () => {
      selectManager.current!.destroy();
    };
    // customOnChange dependency causes unwanted updates
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectManager.current) {
      selectManager.current.valid = !invalid;
    }
  });

  return (
    <StyledDiv
      className={
        'mdc-select mdc-select--filled' +
        (discrete ? ' mdc-select--filled-discrete' : '')
      }
      ref={selectRef}
      {...stylingProps}
    >
      <div
        className="mdc-select__anchor"
        role="button"
        aria-haspopup="listbox"
        aria-expanded="false"
        aria-labelledby="position-label position-selected-text"
      >
        <span className="mdc-select__ripple"></span>
        <span className="mdc-floating-label">{label}</span>
        <span className="mdc-select__selected-text-container">
          <span className="mdc-select__selected-text"></span>
        </span>
        <span className="mdc-select__dropdown-icon">
          <svg
            className="mdc-select__dropdown-icon-graphic"
            viewBox="7 10 10 5"
            focusable="false"
          >
            <polygon
              className="mdc-select__dropdown-icon-inactive"
              stroke="none"
              fillRule="evenodd"
              points="7 10 12 15 17 10"
            ></polygon>
            <polygon
              className="mdc-select__dropdown-icon-active"
              stroke="none"
              fillRule="evenodd"
              points="7 15 12 10 17 15"
            ></polygon>
          </svg>
        </span>
        <span className="mdc-line-ripple"></span>
      </div>
      <Menu className="mdc-select__menu mdc-menu-surface--fullwidth">
        <ul
          className="mdc-list"
          role="listbox"
          aria-label="Food picker listbox"
        >
          {withEmpty && (
            <li
              className="mdc-list-item"
              aria-selected="false"
              data-value=""
              role="option"
              key=""
            >
              <span className="mdc-list-item__ripple"></span>
            </li>
          )}
          {options.map((option) => (
            <li
              className="mdc-list-item"
              aria-selected="false"
              data-value={option.value}
              role="option"
              key={option.value}
            >
              <span className="mdc-list-item__ripple"></span>
              <span className="mdc-list-item__text">{option.label}</span>
            </li>
          ))}
        </ul>
      </Menu>
    </StyledDiv>
  );
};

export default Select;

import React from 'react';

import { Publisher } from 'utils/types';
import {
  chart_link,
  contract_link,
  pancake_link,
  ape_link,
  twitter_link,
} from 'utils/links';

import ContentCard, {
  ContentCardLeft,
  ContentCardProps,
  ContentCardRight,
} from 'components/atoms/ContentCard';
import TokenTitle from 'components/molecules/TokenTitle';
import Links, { Link } from 'components/molecules/Links';
import Box from 'components/atoms/Box';
import Button from 'components/atoms/Button';
import { useHistory } from 'react-router-dom';

interface PublisherInfoProps extends ContentCardProps {
  publisher: Publisher;
  buttonChange?: boolean;
  buttonPost?: boolean;
}

const PublisherInfo: React.FunctionComponent<PublisherInfoProps> = ({
  publisher,
  buttonChange,
  buttonPost,
  ...contentCardProps
}) => {
  const history = useHistory();
  const links: Link[] = [];
  publisher.website &&
    links.push({
      name: 'Website',
      icon: 'world',
      url: publisher.website,
    });
  publisher.telegram_link &&
    links.push({
      name: 'Telegram',
      icon: 'telegram',
      url: publisher.telegram_link,
    });
  publisher.twitter_id &&
    links.push({
      name: 'Twitter',
      icon: 'twitter',
      url: twitter_link(publisher.twitter_id),
    });
  publisher.contract_address &&
    links.push(
      {
        name: 'Contract',
        icon: 'bscscan',
        url: contract_link(publisher.contract_address),
      },
      {
        name: 'Chart',
        icon: 'poo',
        url: chart_link(publisher.contract_address),
      },
      {
        name: 'ApeSwap',
        icon: 'apeswap',
        url: ape_link(publisher.contract_address),
      },
      {
        name: 'PancakeSwap',
        icon: 'pancake',
        url: pancake_link(publisher.contract_address),
      }
    );
  return (
    <ContentCard padding="8px 12px 8px 8px" {...contentCardProps}>
      <ContentCardLeft>
        <TokenTitle
          imgUrl={publisher.image}
          name={publisher.name}
          symbol={publisher.symbol}
          link={
            publisher.is_token
              ? `/token/${publisher.token_id}`
              : `/recruiter/${publisher.id}`
          }
        />
      </ContentCardLeft>
      <ContentCardRight>
        <Box display={['none', 'initial']}>
          <Links links={links} />
        </Box>
        {buttonChange && (
          <Box ml="10px">
            <Button
              outlined
              onClick={() => history.push('/account/recruiter-profile')}
            >
              Change
            </Button>
          </Box>
        )}
        {buttonPost && (
          <Box ml="10px">
            <Button
              outlined
              onClick={() => history.push(`/post-job/${publisher.id}`)}
            >
              Post a Job
            </Button>
          </Box>
        )}
      </ContentCardRight>
    </ContentCard>
  );
};

export default PublisherInfo;
